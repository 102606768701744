import React, { useContext } from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { startCallStyles } from './styles'
import { PhoneView, PhoneViewHeader, PhoneViewFooter, PhonwViewBody } from '../PhoneView'
import { InputField, Dialpad, OutboundNumberSelector } from '../../components'
import { WebPhoneContext } from '../../WebPhoneContext'
import { BackspaceIcon } from 'svg-icons/src'
import CallIcon from '@material-ui/icons/Call'
import { isValidPhoneNumber, formatAsYouType } from 'phone-numbers'

const useStyles = makeStyles(startCallStyles)

function StartCallButton (props: { startCall: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        onClick = {props.startCall}
        classes = {{ root: `${classes.callButton} disable-dragging` }}
        data-testid='placeCall'
    >
        <CallIcon className={'call-icon'} />
    </IconButton>)
}

function RemoveCharacterButton (props: { onClick: ()=>void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        onClick={props.onClick}
        disabled = {props.disabled}
        classes = {{ root: `${classes.removeCharButton} disable-dragging` }}
    >
        <BackspaceIcon secondaryColor={props.disabled ? 'gray' : 'white'}/>
    </IconButton>)
}

/**
 *
 */
export function StartCallView () {
    const classes = useStyles()
    const webPhone = useContext(WebPhoneContext)
    const [inputFieldValue, setInputFieldValue] = React.useState('')
    const onInputFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = event?.target?.value
        if (value.length === 1) value = value.trim()
        const countryCode = (value[0] === '+' || value.substring(0, 3) === '011') ? null : 'US'
        if (isValidPhoneNumber(value) || (value.length > 5 && !/[a-zA-Z]/g.test(value)) || !countryCode) {
            value = formatAsYouType(value, countryCode)
        }
        setInputFieldValue(value)
    }
    const backspace = () => {
        setInputFieldValue(inputFieldValue.slice(0, -1))
    }
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => null
    const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => null
    const dialpadOnClick = (value: string) => {
        setInputFieldValue(inputFieldValue + value)
    }
    const onStartCall = () => {
        if (inputFieldValue) {
            webPhone.startCall(inputFieldValue)
        }
    }
    const header = () => {
        return (<InputField
            onChange={onInputFieldChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            value={inputFieldValue}
            onFocus={(inFocus: boolean) => null}
        />)
    }
    const body = () => {
        return (
            <Grid container direction='column' className={classes.startCallBody}>
                <Grid xs={2} item className='numberSelector'>
                    <OutboundNumberSelector/>
                </Grid>
                <Grid xs={10} item className='dialpadContainer'>
                    <Dialpad onClick={dialpadOnClick}/>
                </Grid>
            </Grid>
        )
    }
    const footer = () => {
        return (
            <Grid container direction='row' className={classes.startCallFooter}>
                <Grid xs={4} item>
                </Grid>
                <Grid xs={4} item className='startCallButton'>
                    <StartCallButton startCall={onStartCall} disabled={!webPhone.ready}/>
                </Grid>
                <Grid xs={4} item className='removeCharButton' >
                    <RemoveCharacterButton onClick={backspace} disabled={inputFieldValue.length === 0}/>
                </Grid>
            </Grid>
        )
    }
    console.log('StartCallView')
    return (<PhoneView
        title={'Make a call'}
    >
        <PhoneViewHeader>
            {header()}
        </PhoneViewHeader>
        <PhonwViewBody>
            {body()}
        </PhonwViewBody>
        <PhoneViewFooter>
            {footer()}
        </PhoneViewFooter>
    </PhoneView>)
}
