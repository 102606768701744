import {
    Session,
    SessionState,
    Invitation,
    InvitationAcceptOptions,
    SessionInviteOptions,
    RequestPendingError,
    SessionDescriptionHandler
} from 'sip.js'
import { Callable } from './Callable'
import { CallUser } from './CallUser'

class MergedCallError extends Error {}
class TransferCallError extends Error {}
/**
 *
 */
enum CallDirection {
    Incoming,
    Outgoing
}
/**
 *
 */
enum CallState {
    Initialized = 'Initialized',
    Connecting = 'Connecting',
    Connected = 'Connected',
    OnHold = 'OnHold',
    Referred = 'Referred',
    Terminating = 'Terminating',
    Terminated = 'Terminated',
}

/**
 *
 */
enum CallQuality {
    Offline = 'Offline',
    Bad = 'Bad',
    Fair = 'Fair',
    Great = 'Great',
}

interface CallAudioSettings {
    recording: {
        enabled: boolean
    },
    playback: {
        enabled: boolean
    }
}
/**
 *
 */
interface CallDelegates {
    onTerminated: () => void
    onHold: (hold: boolean) => void
    onCallUpdated: () => void
    onCallConnected: () => void
}

/**
 *
 */
class Call {
    public users: CallUser[]
    public id: string
    public direction: CallDirection
    public state: CallState
    public callQuality: CallQuality
    public startTime: number
    private mSipSessions: Map<string, Session> = new Map<string, Session>()
    private mSessionAudio: Map<string, HTMLAudioElement> = new Map<string, HTMLAudioElement>()
    private mDelegates: CallDelegates
    private mAudioContext: AudioContext
    private mAudioSettings: CallAudioSettings
    // eslint-disable-next-line no-undef
    private mCallQualityInterval: NodeJS.Timer
    /**
     *
     */
    constructor (direction: CallDirection, session: Session, audioSettings: CallAudioSettings, delegates: CallDelegates) {
        // initialize
        this.startTime = Date.now()
        this.state = direction === CallDirection.Incoming ? CallState.Connecting : CallState.Initialized
        this.direction = direction
        this.id = session.id
        this.mDelegates = delegates
        this.mAudioContext = new AudioContext()
        this.users = []
        this.mAudioSettings = audioSettings
        // then merge session
        this.addSession(session)
        this.setAudioSettings(audioSettings)
    }

    /**
     *
     */
    public async accept (): Promise<void> {
        if (this.state === CallState.Connecting) {
            // Handle incoming INVITE request.
            const constrainsDefault = {
                audio: true,
                video: false
            }
            const options: InvitationAcceptOptions = {
                sessionDescriptionHandlerOptions: {
                    constraints: constrainsDefault
                }
            }
            const invitation: Invitation = this.mSipSessions.get(this.id) as Invitation
            return invitation.accept(options).then(() => {
                this.state = CallState.Connected
            })
        }
        return this.terminate().then(() => Promise.reject(new Error('Can not accept call that is not connecting')))
    }

    /**
     *
     */
    public async reject (): Promise<void> {
        if (this.state === CallState.Connecting) {
            const invitation: Invitation = this.mSipSessions.get(this.id) as Invitation
            return invitation.reject().then(() => this.terminate())
        }
        return this.terminate().then(() => Promise.reject(new Error('Can not reject call that is not connecting')))
    }

    /**
     *
     */
    public async ignore (): Promise<void> {
        if (this.state === CallState.Connecting) {
            const invitation: Invitation = this.mSipSessions.get(this.id) as Invitation
            return invitation.dispose().then(() => this.terminate())
        }
        return this.terminate().then(() => Promise.reject(new Error('Can not ignore call that is not connecting')))
    }

    /**
     * diconnect user from call
     */
    public async disconnect (user: CallUser): Promise<void> {
        // clean up session
        const session = this.mSipSessions.get(user.sessionId)
        if (session) {
            this.removeSession(session)
            return this.terminateSession(session).then(() => {
                // clean up users
                this.users = this.users.filter((u) => u.sessionId !== user.sessionId)
                this.mDelegates.onCallUpdated()
            })
        }
        return Promise.reject(new MergedCallError('CallUser not found'))
    }

    /**
     *
     */
    public async terminate (): Promise<void> {
        // these calls to upate are needed to ensure the UI updates
        // TODO: find a better way to do this
        const terminatePromises: Promise<void>[] = []
        this.state = CallState.Terminating
        this.mDelegates.onCallUpdated()
        // end call
        // clean up all sessions
        this.mSipSessions.forEach((session) => {
            terminatePromises.push(this.terminateSession(session))
        })
        this.state = CallState.Terminated
        this.mDelegates.onTerminated()
        this.stopCallQualityInterval()
        return Promise.all(terminatePromises).then(() => Promise.resolve())
    }

    /**
     * TODO mute individual users
     */
    public async mute (mute: boolean, user: CallUser): Promise<void> {
        // mute user
    }

    /**
     *
     */
    public async hold (hold: boolean): Promise<void> {
        // Verify call is connected or on hold already, and we are not holding a call on hold
        if (this.mSipSessions.size > 1) {
            const enableAudio = !hold
            await this.enableAudioPlayback(enableAudio)
            this.enableAudioRecording(enableAudio)
        } else {
            await this.setHold(hold)
        }
        /* these checks seems unnecessary
        if ((this.isOnHold() || this.isConnected()) && (hold !== this.isOnHold())) {
            if (this.mSipSessions.size > 1) {
                this.enableAudioPlayback(enableAudio)
                this.enableAudioRecording(enableAudio)
            } else {
                // in a single call use SIP hold method
                // FOR NOW DO NOT USE SIP HOLD, DO THIS INSTEAD
                this.enableAudioPlayback(enableAudio)
                this.enableAudioRecording(enableAudio)
                // TODO FIX BUG WITH SIP HOLD
                // await this.setHold(hold)
            }
        }
        */
        this.state = hold ? CallState.OnHold : CallState.Connected
        this.mDelegates.onHold(hold)
    }

    /**
     *
     */
    public async merge (other: Call): Promise<void> {
        await other.setHold(false)
        const sessionToMerge = other.mSipSessions.get(other.id) as Session
        other.removeSession(sessionToMerge)
        console.log('merge session: ', sessionToMerge)
        this.addSession(sessionToMerge)
        this.initAudioElement(sessionToMerge)
        return this.configureSenderAudio().then(() => {
            other.terminate()
            this.mDelegates.onCallUpdated()
        })
    }

    /**
     * @param tone The dial tone
     * @param pressedDuration duration the dial tone was held in milliseconds
     */
    public async sendDTMF (tone: string, pressedDuration = 2000): Promise<void> {
        const sendDTMFPromise: Promise<any>[] = []
        // As RFC 6086 states, sending DTMF via INFO is not standardized...
        //
        // Companies have been using INFO messages in order to transport
        // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
        // proprietary and have not been standardized.
        // https://tools.ietf.org/html/rfc6086#section-2
        //
        // It is however widely supported based on this draft:
        // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00
        // Validate tone
        if (!/^[0-9A-D#*,]$/.exec(tone)) {
            return Promise.reject(new Error('Invalid DTMF tone.'))
        }
        // The UA MUST populate the "application/dtmf-relay" body, as defined
        // earlier, with the button pressed and the duration it was pressed
        // for.  Technically, this actually requires the INFO to be generated
        // when the user *releases* the button, however if the user has still
        // not released a button after 5 seconds, which is the maximum duration
        // supported by this mechanism, the UA should generate the INFO at that
        // time.
        // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3
        const dtmf = tone
        const duration = pressedDuration > 5000 ? 5000 : pressedDuration
        const body = {
            contentDisposition: 'render',
            contentType: 'application/dtmf-relay',
            content: 'Signal=' + dtmf + '\r\nDuration=' + duration
        }
        const requestOptions = { body }
        this.mSipSessions.forEach((session) => {
            sendDTMFPromise.push(session.info({ requestOptions }))
        })
        await Promise.all(sendDTMFPromise)
        return Promise.resolve()
    }

    /**
     *
     */
    private sessionStateListener (session: Session, newState: SessionState): void {
        switch (newState) {
                case SessionState.Initial:
                    break
                case SessionState.Establishing:
                    if (this.state === CallState.Initialized) {
                        this.state = CallState.Connecting
                    }
                    break
                case SessionState.Established:
                    this.state = CallState.Connected
                    this.startTime = Date.now()
                    this.initAudioElement(session)
                    this.configureSenderAudio().then(() => {
                        this.mDelegates.onCallConnected()
                    })
                    break
                case SessionState.Terminating:
                    if (this.mSipSessions.size === 1) {
                        this.state = CallState.Terminating
                    }
                    break
                case SessionState.Terminated:
                    this.removeSession(session)
                    if (this.mSipSessions.size === 0) {
                        this.terminate()
                    }
                    break
                default:
                    break
        }
        this.mDelegates.onCallUpdated()
    }

    /**
     *
     */
    private addSession (session: Session): void {
        this.mSipSessions.set(session.id, session)
        // create user
        const user = new CallUser(session)
        user.subscribe(this.mDelegates.onCallUpdated)
        this.users.push(user)
        // add listener
        const listener = (newState: SessionState) => { this.sessionStateListener(session, newState) }
        session.stateChange.addListener(listener)
        // start call quality interval listener
        this.startCallQualityInterval()
    }

    /**
     * TODO move to own module
     */
    private stopCallQualityInterval (): void {
        if (this.mCallQualityInterval) {
            clearInterval(this.mCallQualityInterval)
            this.mCallQualityInterval = undefined
        }
    }

    /**
     * TODO move to own module, and add info to users
     */
    private startCallQualityInterval (): void {
        this.stopCallQualityInterval()
        const callQualityInterval = async (session: Session) => {
            const peerConnection = (session.sessionDescriptionHandler)?.peerConnection as RTCPeerConnection
            if (!peerConnection) return
            const stats: RTCStatsReport = await peerConnection.getStats(null)
            const connectionStats = {
                audio: {
                    latency: 0,
                    packetsLost: 0,
                    sendCodec: 'N/A',
                    recvCodec: 'N/A',
                    jitter: 0
                }
            }
            stats.forEach(report => {
                // chrome latency
                if (report.type === 'candidate-pair' && report.currentRoundTripTime) {
                    connectionStats.audio.latency = report.currentRoundTripTime / 2
                }
                // TODO: find a way to calculate ff latency - candidate-pair type does not report currentRTT
                // packet loss and jitter
                if (report.type === 'inbound-rtp') {
                    connectionStats.audio.packetsLost = report.packetsLost / report.packetsReceived
                    connectionStats.audio.jitter = report.jitter
                    const key = report.codecId
                    if (report.codecId) {
                        const codecReport = stats.get(key) // as RTCCodecStats
                        const mimeType = codecReport.mimeType
                        if (mimeType) connectionStats.audio.recvCodec = mimeType
                    }
                }

                if (report.type === 'outbound-rtp') {
                    const key = report.codecId
                    if (report.codecId) {
                        const codecReport = stats.get(key) // as RTCCodecStats
                        const mimeType = codecReport.mimeType
                        if (mimeType) connectionStats.audio.sendCodec = mimeType
                    }
                }
            })
            const jitter = connectionStats.audio.jitter
            const packetLoss = connectionStats.audio.packetsLost
            const latency = connectionStats.audio.latency
            const effectiveLatency = latency + (jitter * 2) + 10
            let rVal = 5
            if (effectiveLatency < 160) {
                rVal = 93.2 - effectiveLatency / 40
            } else {
                rVal = 93.2 - (effectiveLatency - 120) / 10
            }
            rVal -= packetLoss * 2.5
            const mosScore = Math.ceil(1 + (0.035 * rVal) + (0.000007 * rVal) * (rVal - 60) * (100 - rVal))
            if (mosScore === 0) {
                this.callQuality = CallQuality.Offline
            } else if (mosScore < 2) {
                this.callQuality = CallQuality.Bad
            } else if (mosScore < 3.5) {
                this.callQuality = CallQuality.Fair
            } else {
                this.callQuality = CallQuality.Great
            }
        }
        const callQualityUpdate = () => {
            const quality = this.callQuality
            callQualityInterval(this.mSipSessions.get(this.id))
            if (quality !== this.callQuality) {
                this.mDelegates.onCallUpdated()
            }
        }
        this.mCallQualityInterval = setInterval(callQualityUpdate, 2000)
    }

    /**
     *
     */
    private cleanUpSessionAudio (session: Session) {
        const audioElement = this.mSessionAudio.get(session.id)
        if (audioElement) {
            audioElement.pause()
            audioElement.remove()
            this.mSessionAudio.delete(session.id)
        }
    }

    /**
     *
     */
    private removeSession (session: Session): void {
        // overwrite last listener
        const listener = (newState: SessionState) => { console.log(newState) }
        session.stateChange.addListener(listener)
        // cleanup audio
        this.cleanUpSessionAudio(session)
        // remove from map
        this.mSipSessions.delete(session.id)
        this.users = this.users.filter((user) => user.sessionId !== session.id)
        this.mDelegates.onCallUpdated()
    }

    /**
     *
     */
    private async terminateSession (session: Session): Promise<void> {
        // cleanup session
        if (session.state === SessionState.Established) await session.bye()
        return session.dispose()
    }

    /**
     *
     */
    private initAudioElement (session: Session): void {
        // init audio object
        const audioElement = new Audio()
        audioElement.id = `mSessionAudio-${session.id}`
        this.mSessionAudio.set(session.id, audioElement)
        if (audioElement) {
            // get session media stream, assign to audio element
            const sdh: any = session.sessionDescriptionHandler
            const pc = sdh.peerConnection
            const receivedStream = new MediaStream()
            receivedStream.addTrack(pc.getReceivers()[0].track)
            audioElement.srcObject = receivedStream
            if (this.state === CallState.Connected) {
                audioElement.play()
            } else {
                audioElement.pause()
            }
        }
    }

    /**
     * Mix sender track for each session in the call.
     * combine local stream and other sessions' streams into one.
     */
    private async configureSenderAudio (): Promise<void> {
        const replaceTrackPromises: Promise<void>[] = []
        this.mSipSessions.forEach(session => {
            const excludeId = session.id
            const mixedStreams = this.mAudioContext.createMediaStreamDestination()
            const sdh = session.sessionDescriptionHandler as SessionDescriptionHandler
            const pc: RTCPeerConnection = sdh.peerConnection
            // Merga all other sessions' audio streams
            this.mSipSessions.forEach((otherSession) => {
                if (otherSession.id !== excludeId) {
                    const otherSdh = otherSession.sessionDescriptionHandler as SessionDescriptionHandler
                    const otherPc: RTCPeerConnection = otherSdh.peerConnection
                    const receivers = otherPc.getReceivers()
                    receivers.forEach(receiver => {
                        const senderStream = this.mAudioContext.createMediaStreamSource(new MediaStream([receiver.track]))
                        senderStream.connect(mixedStreams)
                    })
                }
            })
            // get client's audio stream and add to senderStream
            const track = pc.getSenders()[0].track as MediaStreamTrack
            const localStream = this.mAudioContext.createMediaStreamSource(new MediaStream([track]))
            localStream.connect(mixedStreams)
            // replace original track with merged tracks
            const mixedTrack = mixedStreams.stream.getAudioTracks()[0]
            const sender = pc.getSenders()[0]
            replaceTrackPromises.push(sender.replaceTrack(mixedTrack))
        })
        await Promise.all(replaceTrackPromises)
        return Promise.resolve()
    }

    /** Audio Devices */
    public async setInputAudioDevice (device: MediaDeviceInfo): Promise<void> {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: device.deviceId } })
        for (const [, session] of this.mSipSessions) {
            const sdh = session.sessionDescriptionHandler as SessionDescriptionHandler
            const pc = sdh.peerConnection
            const senders = pc.getSenders()
            senders.forEach(sender => {
                if (sender.track) {
                    sender.replaceTrack(stream.getAudioTracks()[0])
                }
            })
        }
    }

    /**
     *
     */
    public async setOutputAudioDevice (device: MediaDeviceInfo): Promise<void> {
        for (const [, audioElement] of this.mSessionAudio) {
            console.log('PdcCallClient audio output', audioElement, audioElement.setSinkId)
            audioElement.setSinkId(device.deviceId)
        }
        /**
        const audioDestination = this.mAudioContext.destination as unknown as MediaStreamAudioDestinationNode
        const audioTracks = audioDestination.stream.getAudioTracks()
        audioTracks.forEach(track => {
            track.stop()
            audioDestination.stream.removeTrack(track)
        })
        const newTrack = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: device.deviceId } })
        audioDestination.stream.addTrack(newTrack.getAudioTracks()[0])
         */
    }
    /** End Audio Devices */

    /**
     *
     */
    private async enableAudioPlayback (enable: boolean) {
        const enablePlayback = enable
        this.mSipSessions.forEach(async (session) => {
            // audio element
            const audioElement = this.mSessionAudio.get(session.id)
            console.log('audio element: ', audioElement)
            if (audioElement) {
                if (enablePlayback) await audioElement.play()
                else audioElement.pause()
            }
            // receiver track
            const sessionDescriptionHandler = session.sessionDescriptionHandler as SessionDescriptionHandler
            const pc = sessionDescriptionHandler?.peerConnection as RTCPeerConnection
            pc?.getReceivers().forEach((receiver) => {
                if (receiver.track) {
                    receiver.track.enabled = enablePlayback
                }
            })
        })
    }

    /**
     *
     */
    private enableAudioRecording (enable: boolean) {
        const enableRecording = enable
        this.mSipSessions.forEach((session) => {
            const sessionDescriptionHandler = session.sessionDescriptionHandler as SessionDescriptionHandler
            const pc = sessionDescriptionHandler?.peerConnection as RTCPeerConnection
            pc?.getSenders().forEach((sender) => {
                if (sender.track) {
                    sender.track.enabled = enableRecording
                }
            })
        })
    }

    /**
     * Puts Session on hold.
     *
     * @param hold - Hold on if true, off if false.
     */
    private async setHold (hold: boolean): Promise<void> {
        const enableAudio = !hold
        const options: SessionInviteOptions = {
            requestDelegate: {
                onAccept: async (): Promise<void> => {
                    this.state = hold ? CallState.OnHold : CallState.Connected
                    await this.enableAudioPlayback(enableAudio)
                    this.enableAudioRecording(enableAudio)
                    this.mDelegates.onCallUpdated()
                },
                onReject: async (): Promise<void> => {
                    console.warn(`[${this.id}] re-invite request was rejected`)
                    await this.enableAudioPlayback(!enableAudio)
                    this.enableAudioRecording(!enableAudio)
                    this.mDelegates.onCallUpdated()
                }
            },
            sessionDescriptionHandlerOptions: {
                hold: hold,
                constraints: {
                    audio: true,
                    video: false
                }
            }
        }

        const session = this.mSipSessions.get(this.id) as Session
        return session.invite(options).then(() => {
            // preemptively enable/disable tracks
            this.enableAudioPlayback(enableAudio)
            this.enableAudioRecording(enableAudio)
        }).catch((error: Error) => {
            if (error instanceof RequestPendingError) {
                console.error(`[${this.id}] A hold request is already in progress.`)
            }
            console.error('ON HOLD ERROR: ', error)
            // throw error
        })
    }

    /**
     *
     */
    public async setAudioSettings (audioSettings: CallAudioSettings): Promise<void> {
        this.mAudioSettings = audioSettings
        this.enableAudioRecording(audioSettings.recording.enabled)
        await this.enableAudioPlayback(audioSettings.playback.enabled)
    }

    /**
     *
     */
    public getAudioSettings (): CallAudioSettings {
        return this.mAudioSettings
    }

    /**
     *
     */
    public isOnHold (): boolean {
        return this.state === CallState.OnHold
    }

    /**
     *
     */
    public isConnected (): boolean {
        return this.state === CallState.Connected
    }

    /**
     *
     */
    public async transfer (referalString: string): Promise<void> {
        if (this.mSipSessions.size > 1) {
            return Promise.reject(new MergedCallError('Cannot transfer a call with more than one participant.'))
        }
        const session = this.mSipSessions.get(this.id) as Session
        const callable = new Callable(referalString)
        /* const referOptions: SessionReferOptions = {
            requestDelegate: {
                onAccept: (): void => {},
                onProgress: (): void => {},
                onRedirect: (): void => {},
                onReject: (): void => {},
                onTrying: (): void => {},
            }
        } */
        if (callable.isValid) {
            this.state = CallState.Referred
            this.mDelegates.onCallUpdated()
            return session.refer(callable.makeURI()).then(res => {
                this.mDelegates.onCallUpdated()
                return Promise.resolve()
            })
        }
        return Promise.reject(new TransferCallError('Invalid referal string.'))
    }

    /**
     *
     */
    public async refer (otherCall: Call): Promise<void> {
        if (this.mSipSessions.size > 1 || otherCall.mSipSessions.size > 1) {
            return Promise.reject(new MergedCallError('Cannot transfer a call with more than one participant.'))
        }
        const session = this.mSipSessions.get(this.id) as Session
        const otherSession = otherCall.mSipSessions.get(otherCall.id) as Session
        this.state = CallState.Referred
        this.mDelegates.onCallUpdated()
        return session.refer(otherSession).then(res => {
            this.mDelegates.onCallUpdated()
            return Promise.resolve()
        })
    }

    /**
     *
     */
    public userString (): string {
        return this.users.map((user) => user.phoneNumber).sort().join(',')
    }
}

/**
 *
 */
export { Call, CallQuality, CallState, CallDirection, CallDelegates, CallUser }
