import React, { useEffect } from 'react'
import styles from './styles'
import Spinner from 'spinner'
import { makeStyles } from '@material-ui/core/styles'
import { Logo } from '../Logo'
import Typography from 'typography'
import Button from 'button-mui'
import { SignOutComponent } from '../SignOut'
const useStyles = makeStyles(styles)

/**
 *
 */
export const LoadingComponent = () => {
    const classes = useStyles()
    const [showTimeoutMessage, setShowTimeoutMessage] = React.useState(false)
    useEffect(() => {
        setShowTimeoutMessage(false)
        setTimeout(() => {
            setShowTimeoutMessage(true)
        }, 30000)
    }, [])
    const refresh = () => { location.reload() }
    return (
        <div className={classes.loadingScreenRoot}>
            <div hidden={showTimeoutMessage} className={'spinner'}>
                <Spinner size='big'/>
            </div>
            <div className={'timeout-message'} hidden={!showTimeoutMessage}>
                <Typography variant={'subtitle1'} color={'error'} remoteConfigID={'web_phone_load_error'}/>
                <div style={{ padding: '16px' }}>
                    <Button onClick={refresh} variant={'contained'} color={'primary'}>Refresh</Button>
                </div>
            </div>
            <div className={'logo-root'} hidden={showTimeoutMessage}>
                <Logo />
            </div>
            <div className={'sign-out-btn-root'} hidden={!showTimeoutMessage}>
                <SignOutComponent />
            </div>
        </div>
    )
}

export default LoadingComponent
