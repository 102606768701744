/**
 *
 */
const settingsStyles = (theme) => ({
    settingsRoot: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: '1222',
        backgroundColor: 'white',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',

        '& .tones-section': {
            rowGap: '10px',
            '& .section-root': {
                rowGap: '10px',
                '& .switch-container': {
                    alignSelf: 'center',
                    textAlign: 'right',
                    '& .Mui-checked + *': {
                        backgroundColor: theme.palette.primary.brandColor
                    }
                }
            }
        },
        '& .volume-section': {
            rowGap: '10px',
            '& .section-root': {
                '& .volume-container': {
                    alignItems: 'center',
                    columnGap: '15px',
                    '& svg': {
                        color: `${theme.palette.text.primary}!important`
                    },
                    '& .iconBtn': {
                        backgroundColor: 'transparent!important'
                    }
                }
            }
        },
        '& .disconnect-section': {
            '& .section-root': {
                padding: '20px',
                '& .disconnect-section-btn': {
                    margin: 'auto'
                }
            }
        },
        '& .device-section': {
            '&. device-selector': {
            }
        },
        '& .app-version': {
            fontSize: '12px !important',
            margin: 'auto'
        },
        '& .section-heading': {
            fontFamily: 'Montserrat!important',
            color: `${theme.palette.info.dark}!important`,
            fontSize: '12px!important',
            fontWeight: '600!important',
            lineHeight: '19px!important'
        },
        '& .section-item-heading': {
            fontFamily: 'Montserrat!important',
            color: `${theme.palette.text.primary}!important`,
            fontSize: '12px!important',
            fontWeight: '600!important',
            lineHeight: '19px!important'
        },
        '& .section-item-details': {
            fontFamily: 'Montserrat!important',
            color: `${theme.palette.text.secondary}!important`,
            fontSize: '11px!important',
            fontWeight: '500!important',
            lineHeight: '16px!important'
        },

        '& > .settings': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',
            padding: '42px 18px 0 18px',
            overflowY: 'auto'
        }
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 10
    }
})

/**
 *
 */
export { settingsStyles }
