import React, { useContext } from 'react'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import { TextareaAutosize } from '@material-ui/core'
import Button from 'button-mui'
import Spinner from 'spinner'
import { WebPhoneContext } from '../../WebPhoneContext'

interface AddNotesPanelProps {
    hidden: boolean
    recordId?: string
    onCloseNotes: () => void
}
const useStyles = makeStyles(styles)
/**
 *
 */
export function AddNotesPanel (props: AddNotesPanelProps): JSX.Element {
    const classes = useStyles()
    const [saving, setSaving] = React.useState(false)
    const [notes, setNotes] = React.useState('')
    const webPhone = useContext(WebPhoneContext)
    const onSave = () => {
        setSaving(true)
        const notes = document.getElementById('notestextarea') as HTMLTextAreaElement
        webPhone.actions.addCallNotes(props.recordId, notes.value).then(() => {
            notes.value = ''
            setNotes('')
            setSaving(false)
        })
    }
    return (<div className={classes.addNotesPanel} hidden={props.hidden}>
        <TextareaAutosize
            id='notestextarea'
            placeholder="Add notes..."
            className={'notesTextArea'}
            rowsMin={8}
            rowsMax={12}
            value={notes}
            onChange={(value) => { setNotes(value.target.value) }}
        />
        <div className={classes.buttons}>
            {saving
                ? <Spinner size='big' />
                : <>
                    <Button color='secondary' onClick={props.onCloseNotes}>Close</Button>
                    <Button onClick={onSave} color='primary' disabled={props.recordId === null || notes === ''}>Save</Button>
                </>}
        </div>
    </div>)
}
