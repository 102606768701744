import React, { useContext, useEffect, useState } from 'react'
import { WebPhoneContext } from './WebPhoneContext'
import { StartCallView } from './views/StartCall'
import { OngoingCallsView } from './views/CallView'
import { IntegrationComponent } from './components/IntegrationComponent'
import { UpgradeToProComponent } from './components/UpgradeToProComponent'
enum WebPhoneState {
    Loading,
    Disconnected,
    Connected,
    Ready
}

/**
 *
 */
export function WebPhoneView () : JSX.Element {
    const phoneContext = useContext(WebPhoneContext)
    const [connected, setConnected] = useState(phoneContext.connected)
    const [ready, setReady] = useState(phoneContext.ready)
    const [calls, setCalls] = useState(phoneContext.calls || [])
    const [showIntegrationRedirect, setShowIntegrationRedirect] = useState(phoneContext.integrationDetails && !phoneContext.integrationDetails.integrated)
    const [showPlanUpgrade, setShowPlanUpgrade] = useState(phoneContext.integrationDetails && phoneContext.userInfo.userType !== 'pro')
    const [phoneState, setPhoneState] = useState(WebPhoneState.Loading)
    useEffect(() => {
        setCalls(phoneContext.calls)
        setConnected(phoneContext.connected)
        setReady(phoneContext.ready)
        if (connected) {
            setPhoneState(WebPhoneState.Connected)
            if (ready) {
                setPhoneState(WebPhoneState.Ready)
            }
        } else if (phoneState !== WebPhoneState.Loading) {
            setPhoneState(WebPhoneState.Disconnected)
        }
    }, [phoneContext, phoneContext.calls])
    useEffect(() => {
        if (phoneContext.integrationDetails) {
            setShowPlanUpgrade(phoneContext.userInfo.userType !== 'pro')
            setShowIntegrationRedirect(!phoneContext.integrationDetails.integrated)
        } else {
            setShowIntegrationRedirect(false)
        }
    }, [phoneContext.integrationDetails])

    if (showPlanUpgrade) return <UpgradeToProComponent userType={phoneContext.userInfo.userType} />
    if (showIntegrationRedirect) return <IntegrationComponent details={phoneContext.integrationDetails}/>
    return (calls.length > 0 ? <OngoingCallsView calls={calls}/> : <StartCallView/>)
}
