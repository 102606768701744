/**
 *
 */
const styles = (theme) => ({
    logoRoot: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    phonecom: {
        fontFamily: 'Montserrat-medium!important',
        color: '#000!important',
        fontWeight: '600!important',
        marginLeft: '4px'
    }
})

export default styles
