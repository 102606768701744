import React from 'react'
// import { makeStyles } from '@material-ui/core'
import { useContext } from 'react'
import Typography from 'typography'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { WebPhoneContext } from '../../WebPhoneContext'
import styles from './styles'
import SignOut from '../SVG/SignOut'

const useStyles = makeStyles(styles)

/**
 *
 */
export function SignOutComponent (props: { logout?: () => void }): JSX.Element {
    const classes = useStyles()
    const webPhoneContext = useContext(WebPhoneContext)
    const logout = props.logout || webPhoneContext.logout
    return (
        <div className={classes.signOutRoot}>
            <Button className='sign-out-btn' startIcon={<SignOut />} onClick={logout}>
                <Typography className='sign-out-btn-text'>Sign out</Typography>
            </Button>
        </div>
    )
}
