import Typography from 'typography'
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { toolbarStyles } from './styles'
import { Call, CallDirection, CallState, WebPhoneContext } from '../../WebPhoneContext'
import { ArrowDropdownIcon, CallIncomingIcon, PauseIcon, MinimizeIcon, SettingsIcon, HangUpIcon } from 'svg-icons'
import { Grid } from '@material-ui/core'
import Timer from 'timer'
import IconButton from 'icon-button-mui'
import { PhoneSettings } from './PhoneSettings'

const useStyles = makeStyles(toolbarStyles)

interface PhoneToolbarProps {
    title: string
    icon?: JSX.Element
}

function ToolbarTitle (props: { title: string, icon?: JSX.Element }): JSX.Element {
    return (<span>
        {props.icon}<Typography variant={'subtitle1'} className={'title'} component={'span'}>{props.title}</Typography>
    </span>)
}

interface CallsIcon {
    count: number
}
function IncomingCallsIcon (props: CallsIcon): JSX.Element {
    if (props.count === 0) return (<></>)
    return (<span className={'callCounter'}>
        <span className={'counterIcon'}><CallIncomingIcon /></span>
        <Typography variant={'subtitle2'} component={'span'}>{props.count}</Typography>
    </span>)
}
function ConnectedCallsCount (props: CallsIcon): JSX.Element {
    if (props.count === 0) return (<></>)
    return (<Typography variant={'subtitle2'} component={'span'}>{props.count} Calls</Typography>)
}
function OnHoldCallsIcon (props: CallsIcon): JSX.Element {
    if (props.count === 0) return (<></>)
    return (<span className={'callCounter'}>
        <span className={'counterIcon'}><PauseIcon /></span>
        <Typography className={'counterText'} variant={'subtitle2'} component={'span'}>{props.count}</Typography>
    </span>)
}
function SettingsButton (props: { openSettings: () => void }): JSX.Element {
    return (<span className={'settingsButton'}>
        <SettingsIcon onClick={props.openSettings}/>
    </span>)
}
function MinimizeButton (): JSX.Element {
    const phoneContext = useContext(WebPhoneContext)
    return (<span onClick={phoneContext.minimizeWebPhone} className={'minimizeButton'}>
        <MinimizeIcon />
    </span>)
}

function ToolbarItems (): JSX.Element {
    const [toggleSettings, setToggleSettings] = useState(false)
    return (<Grid container spacing={1} className={'toolbarItems'}>
        <Grid item>
            {toggleSettings && <PhoneSettings onClose={() => setToggleSettings(false)}/>}
            <SettingsButton openSettings={() => setToggleSettings(true)}/>
        </Grid>
        <Grid item>
            <MinimizeButton />
        </Grid>
    </Grid>)
}

function DefaultToolbar (props: PhoneToolbarProps): JSX.Element {
    const classes = useStyles()
    return (<div className={classes.toolbarRoot}>
        <Grid container className={'defaultToolbar'}>
            <Grid item xs={6}>
                <ToolbarTitle {...props} />
            </Grid>
            <Grid item xs={6}>
                <ToolbarItems />
            </Grid>
        </Grid>
    </div>)
}

function CallsListToggleButton (props: { onClick: () => void, expanded: boolean}): JSX.Element {
    const className = props.expanded ? 'callsListToggleButton expanded' : 'callsListToggleButton'
    return (<span className={className}>
        <ArrowDropdownIcon onClick={props.onClick} />
    </span>)
}

function CallsListDropdown (props: { calls: Call[], expanded: boolean}): JSX.Element {
    const expanded = props.expanded ? 'expanded' : ''
    const classes = useStyles()
    const callItem = (call: Call) => {
        const users = call.users
        const onHoldColor = call.isOnHold() ? 'error' : 'inherit'
        const onCallClick = call.isOnHold() ? () => call.hold(false) : () => null
        const terminateCall = () => call.terminate()
        return (<div className={classes.callItem} key={call.id}>
            <Grid container>
                <Grid item xs={6} className={'userList'} onClick={onCallClick}>
                    <div>
                        {users.map(user => {
                            return <div key={user.phoneNumber}>
                                <Typography color={onHoldColor} variant={'subtitle2'} component={'span'}>
                                    {user.displayName}
                                </Typography>
                            </div>
                        })}
                    </div>
                </Grid>
                <Grid className={'timer'} item xs={2}>
                    <Typography color={onHoldColor} variant={'subtitle2'} component={'span'}>
                        <Timer startTime={call.startTime / 1000} />
                    </Typography>
                </Grid>
                <Grid item className={'callOptions'} xs={4}>
                    <span className={'holdIcon'} hidden={!call.isOnHold()}>
                        <PauseIcon/>
                    </span>
                    <IconButton
                        color={'urgent'}
                        className={'endCallButton disable-dragging'}
                        onClick={terminateCall}
                    >
                        <HangUpIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </div>)
    }
    return (<div className={'callsListDropdown ' + expanded}>
        {props.calls.map(callItem)}
    </div>)
}

function OngoingCallsToolbar (props: PhoneToolbarProps): JSX.Element {
    const classes = useStyles()
    const phoneContext = useContext(WebPhoneContext)
    const calls = phoneContext.calls
    const incomingCallsCount = () => {
        return calls.filter(call => call.direction === CallDirection.Incoming && call.state === CallState.Connecting).length
    }
    const onHoldCallsCount = () => {
        return calls.filter(call => call.state === CallState.OnHold).length
    }
    const [callsListExpanded, setCallsListExpanded] = useState(false)
    const toggleCallsList = () => { setCallsListExpanded(!callsListExpanded) }
    return (<div className={classes.toolbarRoot}>
        <Grid container spacing={2} className={'ongoingCallsToolbar'}>
            <Grid item xs={8} className={'counterSection'}>
                <CallsListToggleButton onClick={toggleCallsList} expanded={callsListExpanded}/>
                <ConnectedCallsCount count={calls.length}/>
                <OnHoldCallsIcon count={onHoldCallsCount()}/>
                <IncomingCallsIcon count={incomingCallsCount()}/>
            </Grid>
            <Grid item xs={4}>
                <ToolbarItems/>
            </Grid>
        </Grid>
        <CallsListDropdown calls={calls} expanded={callsListExpanded}/>
        <div style={{ marginLeft: '10px', marginTop: '5px' }}>
            <ToolbarTitle {...props} />
        </div>
    </div>)
}

/**
 *
 */
export function PhoneToolbar (props: PhoneToolbarProps): JSX.Element {
    const [showOngoingCalls, setShowOngoingCalls] = useState(false)
    const phoneContext = useContext(WebPhoneContext)
    useEffect(() => {
        setShowOngoingCalls(phoneContext.calls?.length > 1)
    }, [phoneContext.calls])
    const ongoingCalls = showOngoingCalls ? <OngoingCallsToolbar {...props} /> : <DefaultToolbar {...props} />
    return (<>{ongoingCalls}</>)
}
