import Dexie, { Table } from 'dexie'
import { IContact, IUserInfo } from './models'
if (!Dexie.dependencies.indexedDB || !Dexie.dependencies.IDBKeyRange) {
    Dexie.dependencies.indexedDB = require('fake-indexeddb')
    Dexie.dependencies.IDBKeyRange = require('fake-indexeddb/lib/FDBKeyRange')
}

interface DefaultTable {
    id?: number,
    key: string,
    value: number | string | boolean
}

// Table schemes
const DefaultTableScheme = '++id, key, value'
const UserInfoStoreScheme = 'sessionId, voipId, voipPhoneId, role, userId, legacyUser'
class MyPdcDexie extends Dexie {
    mypdc: Table<DefaultTable>
    contacts: Table<IContact>
    userInfo: Table<IUserInfo>
    constructor () {
        super('MyPdcClient')
        this.version(1).stores({
            mypdc: DefaultTableScheme,
            userInfo: UserInfoStoreScheme
        })
    }
}

/***/
const db = new MyPdcDexie()
/**
 *
 */
export { db }
