/**
 *
 */
const startCallStyles = (theme) => ({
    startCallBody: {
        alignItems: 'center',
        '& .numberSelector': {
            minWidth: '100%',
            height: '40px'
        },
        '& .dialpadContainer': {
            width: '70%'
        }
    },
    startCallFooter: {
        width: '75%',
        margin: 'auto',
        padding: '5px 5px 0 5px',
        '& .startCallButton': {
            display: 'flex',
            justifyContent: 'center'
        },
        '& .removeCharButton': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    callButton: {
        width: 54,
        height: 54,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.action.disabledBg
        },
        '& .call-icon': {
            fontSize: '24px'
        }
    },
    removeCharButton: {
        backgroundColor: 'transparent',
        '& svg': {
            color: theme.palette.secondary.main,
            width: 30,
            height: 30
        },
        '&:hover, &:focus, &:focus-visible': {
            backgroundColor: 'transparent'
        },
        '&.Mui-disabled': {
            backgroundColor: 'transparent',
            '& svg': {
                color: theme.palette.action.disabledBg
            }
        }
    }
})

/**
 *
 */
export { startCallStyles }
