import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { WebPhoneContextComponent } from './WebPhoneContext'
import { WebPhoneView } from './WebPhoneView'
import { WebPhoneContext } from './WebPhoneContext'
import { styles } from './styles'
import { AudioDevicesComponent } from 'audio-devices'
import { IContactInfo } from 'pdc-client'
interface WebPhoneHandler {
    startCall: (calleeNumber: string) => void
    maximizeWebPhone: () => void
    minimizeWebPhone: () => void
    getLocalContacts: (searchString: string) => IContactInfo[]
}
declare global {
    interface Window {
        WebPhoneHandler: WebPhoneHandler
    }
}

/**
 *
 */
window.WebPhoneHandler = {
    startCall: (number) => {
        console.log('No Web Phone Context')
    },
    maximizeWebPhone: () => null,
    minimizeWebPhone: () => null,
    getLocalContacts: (searchString) => []
}

function Handler (): JSX.Element {
    const webPhone = useContext(WebPhoneContext)
    window.WebPhoneHandler.startCall = webPhone.startCall
    return (<span></span>)
}

const useStyles = makeStyles(styles)

/**
 *
 */
export function WebPhone (): JSX.Element {
    const classes = useStyles()
    return (
        <div id='webphone' className={`${classes.webPhoneRoot}`}>
            <AudioDevicesComponent>
                <WebPhoneContextComponent>
                    <WebPhoneView/>
                    <Handler/>
                </WebPhoneContextComponent>
            </AudioDevicesComponent>
        </div>
    )
}
