import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    mainField: {
        padding: '0px 30px',
        display: 'flex',
        '& .MuiInputBase-root.Mui-focused:not(.Mui-error)': {
            background: 'transparent'
        },
        '& .MuiInput-root input': {
            color: 'white',
            caretColor: '#37CFFF',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 500
        },
        '& .MuiInputBase-root.MuiInput-root': {
            marginTop: '0px'//,
            // width: '90%'
        },
        '& .MuiInputLabel-root': {
            color: 'white',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        '& .MuiInputLabel-shrink': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'none'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        }
    },
    mainFieldLabel: {
        width: '100%',
        textAlign: 'center',
        left: 0
    }
})

export default styles
