import {
    UserAgent,
    Inviter,
    Session,
    Invitation,
    InviterOptions,
    UserAgentOptions,
    Registerer,
    RegistererState,
    Web,
    URI,
    SessionDelegate
} from 'sip.js'
import { Call, CallDirection, CallDelegates, CallState } from './Call'
import { Callable } from './Callable'
import { ISipAuthenticaiton } from 'my-pdc-client'
import { getInputMediaStream } from 'audio-devices'
/**
 *
 */
interface ISipClientDelegates {
    onIncomingCall: (call: Call) => void,
    onOutgoingCall: (call: Call) => void,
    onCallsListChange: (calls: Call[]) => void,
    onClientRegistered: (isRegistered: boolean) => void,
    onClientConnected: () => void,
    onClientDisconnected: (error: Error) => void,
}

class SipClientError extends Error {}
/**
 *
 */
class StartCallError extends SipClientError {}
/**
 *
 */
class SipClient {
    private mUserAgent: UserAgent
    private mRegisterer: Registerer
    private mDelegates: ISipClientDelegates
    private mCalls: Call[]
    private mAudioOutputEnabled: boolean
    private mAudioInputEnabled: boolean
    private mAudioOutputDevice: MediaDeviceInfo
    private mAudioInputDevice: MediaDeviceInfo
    private mPaused: boolean
    /*
    * Intialize and register the user agent object.
    * @param sipAuth - SIP authentication credentials
    */
    /**
     *
     */
    constructor (authentication: ISipAuthenticaiton, delegates: ISipClientDelegates, devices: { input: MediaDeviceInfo, output: MediaDeviceInfo }) {
        this.mDelegates = delegates
        this.mCalls = []
        this.mAudioOutputEnabled = true
        this.mAudioInputEnabled = true
        this.mAudioOutputDevice = devices.output
        this.mAudioInputDevice = devices.input
        this.mPaused = false
        // Create the user agent
        this.mUserAgent = this.createUserAgent(authentication)
        // Create the registerer
        this.mRegisterer = new Registerer(this.mUserAgent, {
            expires: 3600, // 1 hour
            refreshFrequency: 50 // 50% of expires time
        })
        this.mRegisterer.stateChange.addListener((state) => {
            if (state === RegistererState.Registered) {
                this.mDelegates.onClientRegistered(true)
            } else {
                this.mDelegates.onClientRegistered(false)
                const retry = () => {
                    console.log('sipclient register retry')
                    if (this.mRegisterer.state !== RegistererState.Registered) {
                        this.mRegisterer.register().catch(() => {
                            setTimeout(retry, 2000)
                        })
                    }
                }
                retry()
            }
        })
        /**
         * The following is a hack for Firefox to make the hold feature work.
         * Found here https://github.com/onsip/SIP.js/issues/840
         * Sipjs developers believe the issue is related to how our VOIP server hanles the a=sendonly
         */
        const transport = this.mUserAgent._transport
        transport.origSend = transport.send
        transport.send = function (s) {
            const cSeqInviteArr = s.match('CSeq: (\\d+) INVITE')
            if (s.search('o=mozilla') > -1 && cSeqInviteArr && parseInt(cSeqInviteArr[1]) > 2 && s.search('a=sendonly\r\n') > -1) {
                return transport.origSend(s.replace(/a=sendrecv\r\n/g, ''))
            }
            return transport.origSend(s)
        }
        // END FIREFOX HOLD HACK
        // start user agent, connecting the transport and registering the user agent
        this.mUserAgent.start()
    }

    /**
     *
     */
    private createUserAgent (authentication: ISipAuthenticaiton): UserAgent {
        const username = authentication?.username + 'x0'
        const host = 'sip.phone.com'
        const port = '9998'
        const password = authentication?.password
        const ua = {
            traceSip: true,
            // uri: username + '@' + host,
            uri: username + '@' + 'phone.com',
            wsServers: ['wss://' + host + ':' + port],
            authorizationUser: username,
            password: password,
            realm: 'phone.com',
            userAgentString: 'CommunicatorWeb/' + 'v1'
        }
        const uri = 'sip:' + ua.uri
        // Get local recording and playback media stream.
        const myMediaStreamFactory: Web.MediaStreamFactory = (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-undef
            constraints: MediaStreamConstraints,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-undef
            sessionDescriptionHandler: Web.SessionDescriptionHandler
        ): Promise<MediaStream> => {
            return getInputMediaStream()
        }
        const mySessionDescriptionHandlerFactory: Web.SessionDescriptionHandlerFactory = Web.defaultSessionDescriptionHandlerFactory(myMediaStreamFactory)
        const userAgentOptions: UserAgentOptions = {
            uri: UserAgent.makeURI(uri),
            authorizationPassword: password,
            authorizationUsername: username,
            reconnectionAttempts: 999999999,
            reconnectionDelay: 5,
            logBuiltinEnabled: true,
            userAgentString: 'CommunicatorWeb/' + 'v1',
            displayName: 'display name', // TODO: get from user
            transportOptions: {
                server: ua.wsServers[0]
            },
            // dtmfType: 'rtp', // I do not think this is used since we do it manually
            autoStop: true,
            logLevel: 'debug',
            logConfiguration: true,
            delegate: {
                onInvite: this.onInvitationReceived,
                onConnect: () => {
                    console.log('SipClient onConnect')
                    this.mDelegates.onClientConnected()
                    this.mRegisterer.register().then((e) => {
                        console.log('sipclient register: ', e)
                    }).catch(e => console.log(e))
                },
                onDisconnect: (error) => {
                    console.log('SipClient onDisconnect')
                    this.mDelegates.onClientDisconnected(error)
                },
                onMessage: (message) => console.log('SipClient onMessage: ', message),
                onNotify: (notification) => console.log('SipClient onNotify: ', notification)
            },
            sessionDescriptionHandlerFactory: mySessionDescriptionHandlerFactory,
            sessionDescriptionHandlerFactoryOptions: { iceGatheringTimeout: 1, constraints: { audio: true, video: false } }
        }
        return new UserAgent(userAgentOptions)
    }

    /**
     *
     */
    private async onCallHold (id: string, hold: boolean): Promise<void> {
        if (!hold) {
            await this.putOtherCallsOnHold(id)
        }
        this.onCallUpdated()
        return Promise.resolve()
    }

    /**
     *
     */
    private async putOtherCallsOnHold (id: string): Promise<void> {
        const holdPromises: Promise<void>[] = []
        this.mCalls.forEach((call) => {
            if (call.id !== id && call.state === CallState.Connected) {
                holdPromises.push(call.hold(true))
            }
        })
        await Promise.all(holdPromises)
        return Promise.resolve()
    }

    private onCallConnected = (session: Session) => {
        this.onCallHold(session.id, false)
        const call = this.mCalls.find((call) => call.id === session.id)
        call.setOutputAudioDevice(this.mAudioOutputDevice)
        // call.setInputAudioDevice(this.mAudioInputDevice)
    }

    /**
     *
     */
    private onCallTerminated (id: string): void {
        this.mCalls = this.mCalls.filter((call) => call.id !== id)
        this.onCallUpdated()
    }

    /**
     *
     */
    private createCallDelegates = (session: Session): CallDelegates => {
        return {
            onTerminated: () => this.onCallTerminated(session.id),
            onHold: (hold: boolean) => this.onCallHold(session.id, hold),
            onCallUpdated: this.onCallUpdated,
            onCallConnected: () => this.onCallConnected(session)
        }
    }

    /**
     *
     */
    private sendInvite = async (target: URI, extraHeaders = [], delegate: SessionDelegate = {}): Promise<Call> => {
        // Create a new Inviter
        const inviterOptions: InviterOptions =
        {
            sessionDescriptionHandlerOptions: {
                constraints: { audio: true, video: false }
            },
            delegate: delegate,
            extraHeaders: extraHeaders
        }
        const inviter = new Inviter(this.mUserAgent, target, inviterOptions)
        const outgoingSession: Session = inviter
        const delegates = this.createCallDelegates(outgoingSession)
        this.setAudioInputEnabled(true)
        this.setAudioOutputEnabled(true)
        const audioSettings = {
            recording: {
                enabled: this.mAudioInputEnabled
            },
            playback: {
                enabled: this.mAudioOutputEnabled
            }
        }
        const call = new Call(CallDirection.Outgoing, outgoingSession, audioSettings, delegates)
        await inviter.invite().then(() => {
            this.mCalls.forEach((call) => {
                if (call.state === CallState.Connected) {
                    call.hold(true)
                }
            })
            this.mCalls.push(call)
            this.onCallsListChange()
            this.mDelegates.onOutgoingCall(call)
        })
        return call
    }

    /**
     *
     */
    private onInvitationReceived = (invitation: Invitation): void => {
        if (this.mPaused) return
        const incomingSession: Session = invitation
        // create new call
        const delegates = this.createCallDelegates(incomingSession)
        this.setAudioInputEnabled(true)
        this.setAudioOutputEnabled(true)
        const audioSettings = {
            recording: {
                enabled: true
            },
            playback: {
                enabled: true
            }
        }
        const call = new Call(CallDirection.Incoming, incomingSession, audioSettings, delegates)
        this.mCalls.push(call)
        this.onCallsListChange()
        this.mDelegates.onIncomingCall(call)
    }

    private onCallUpdated = (): void => {
        this.onCallsListChange()
    }

    /**
     *
     */
    private onCallsListChange = (): void => {
        this.mDelegates.onCallsListChange(this.listCalls())
    }

    /**
     *
     */
    public async startCall (number: string): Promise<Call> {
        // if (!this.mRegistered) throw new StartCallError('Client is not registered.')
        // await this.putAllCallsOnHold()
        if (this.mPaused) throw new StartCallError('Client is paused.')
        const callee = new Callable(number)
        if (callee.isValid) {
            const targetUri = UserAgent.makeURI(`sip:${callee.number}@phone.com`)
            if (!targetUri) {
                throw new StartCallError('Failed to create target URI.')
            }

            return this.sendInvite(targetUri)
        } else {
            throw new StartCallError(`Entry is not callable: ${number}`)
        }
    }

    /**
     *
     */
    public listCalls (): Call[] { return [...this.mCalls] }

    /**
     *
     */
    public async startClient (): Promise<void> {
        this.mPaused = false
        // return this.mUserAgent.start()
    }

    /**
     *
     */
    public async stopClient (): Promise<void> {
        this.mPaused = true
        /*
        return this.mUserAgent.stop().catch((error) => {
            console.log('SipClient stop error: ', error)
        })
        */
    }

    /**
     *
     */
    public setAudioInputEnabled (enabled: boolean): void {
        console.log('SipClient setAudioInputEnabled: ', enabled)
        this.mAudioInputEnabled = enabled
        const callAudioSettings = {
            recording: {
                enabled: this.mAudioInputEnabled
            },
            playback: {
                enabled: this.mAudioOutputEnabled
            }
        }
        this.mCalls.forEach((call) => {
            call.setAudioSettings(callAudioSettings)
        })
    }

    /**
     *
     */
    public setAudioOutputEnabled (enabled: boolean): void {
        console.log('SipClient setAudioOutputEnabled: ', enabled)
        this.mAudioOutputEnabled = enabled
        const callAudioSettings = {
            recording: {
                enabled: this.mAudioInputEnabled
            },
            playback: {
                enabled: this.mAudioOutputEnabled
            }
        }
        this.mCalls.forEach((call) => {
            call.setAudioSettings(callAudioSettings)
        })
    }

    /**
     *
     */
    public isAudioInputEnabled (): boolean {
        return this.mAudioInputEnabled
    }

    /**
     *
     */
    public isAudioOutputEnabled (): boolean {
        return this.mAudioOutputEnabled
    }

    /**
     *
     */
    public setOutputAudioDevice (device: MediaDeviceInfo): void {
        this.mAudioOutputDevice = device
        for (const call of this.mCalls) {
            call.setOutputAudioDevice(device)
        }
    }

    /**
     *
     */
    public getOutputAudioDevice (): MediaDeviceInfo {
        return this.mAudioOutputDevice
    }

    /**
     *
     */
    public setInputAudioDevice (device: MediaDeviceInfo): void {
        this.mAudioInputDevice = device
        for (const call of this.mCalls) {
            call.setInputAudioDevice(device)
        }
    }

    /**
     *
     */
    public getInputAudioDevice (): MediaDeviceInfo {
        return this.mAudioInputDevice
    }
}

/**
 *
 */
export { SipClient, ISipClientDelegates, StartCallError }
