/**
 *
 */
const toolbarStyles = (theme) => ({
    toolbarRoot: {
        width: '100%',
        zIndex: '1220',
        maxHeight: '65px',
        '& .title': {
            fontSize: '13px!important',
            fontWeight: '600!important'
        },
        '& .defaultToolbar': {
            padding: '10px 10px 5px 10px'
        },
        '& .ongoingCallsToolbar': {
            backgroundColor: 'black',
            padding: '10px 10px 5px 10px'
        },
        '& .toolbarItems': {
            justifyContent: 'flex-end'
        },
        '& .minimizeButton': {
            cursor: 'pointer'
        },
        '& .settingsButton': {
            cursor: 'pointer'
        },
        '& .counterSection': {
            display: 'flex',
            justifyContent: 'left',
            '& .callCounter': {
                height: '18px',
                margin: '0px 10px',
                '& .counterIcon': {
                    height: '18px',
                    width: '28px',
                    position: 'relative'
                },
                '& .counterText': {
                    margin: '0px 5px'
                }
            }
        },
        '& .callsListToggleButton': {
            width: '24px',
            height: '24px',
            marginRight: '1px',
            cursor: 'pointer',
            '&.expanded': {
                transform: 'scale(1, -1)'
            }
        },
        '& .callsListDropdown': {
            display: 'none',
            '&.expanded': {
                display: 'block'
            },
            zIndex: '1221',
            position: 'absolute',
            width: '100%',
            minHeight: '65px',
            border: '1px solid #e0e0e0',
            backgroundColor: 'white',
            color: 'black',
            padding: '15px'
        }
    },
    callItem: {
        color: 'black',
        cursor: 'pointer',
        padding: '5px 0px',
        borderBottom: '1px solid #e0e0e0',
        '& .userList': {
            margin: 'auto 0px'
        },
        '& .timer': {
            margin: 'auto 0px'
        },
        '& .callOptions': {
            display: 'flex',
            justifyContent: 'flex-end',
            '& .endCallButton': {
                height: '36px',
                width: '36px',
                margin: '0px 5px',
                '& svg': {
                    fontSize: '16px'
                }
            },
            '& .holdIcon': {
                margin: 'auto',
                color: 'red'
            }
        }
    }
})

/**
 *
 */
export { toolbarStyles }
