import { ObservableState } from 'observable'
import { PdcSession } from './PdcSession'
import { IContactInfo, IUserInfo } from './models'
import { PdcRestApi } from './PdcRestApi'
import { PdcDataStore } from './PdcDataStore'
interface PdcClientState {
    initialized: boolean
    connected: boolean
    userInfo: IUserInfo
}
interface PdcAppConfig {
    sessionToken: string,
    redirect: () => void,
    listener: (state: PdcClientState) => void,
    localStore?: {
        getLocalContacts?: (filter: string) => Promise<IContactInfo[]>
    }
}

class PdcClientApp extends ObservableState<PdcClientState> {
    private static mSession: PdcSession
    private static mApi: PdcRestApi
    private static mDataStore: PdcDataStore
    private static mInstance: PdcClientApp
    /**
     *
     */
    private constructor (config: PdcAppConfig) {
        super({
            initialized: false,
            connected: false,
            userInfo: null
        })
        if (PdcClientApp.mInstance) {
            return PdcClientApp.mInstance
        } else {
            PdcClientApp.mInstance = this
        }
        const { listener } = config
        this.subscribe(listener)
    }

    /**
     *
     */
    public static async initialize (config: PdcAppConfig): Promise<PdcClientApp> {
        const { sessionToken, redirect } = config
        console.log('pdc client init session', sessionToken)
        const client = new PdcClientApp(config)
        try {
            PdcClientApp.mSession = await PdcSession.initialize(sessionToken, redirect)
            PdcClientApp.mApi = new PdcRestApi()
            PdcClientApp.mDataStore = await new PdcDataStore({
                getLocalContacts: config.localStore?.getLocalContacts
            })
        } catch (error) {
            console.log(error)
        }
        PdcClientApp.mInstance.setState({
            initialized: true,
            connected: true,
            userInfo: PdcSession.getUserInfo()
        })
        return Promise.resolve(client)
    }

    /**
     *
     */
    public static user (): IUserInfo {
        return PdcSession.getUserInfo()
    }

    /**
     *
     */
    public static api (): PdcRestApi {
        return PdcClientApp.mApi
    }

    /**
     *
     */
    public store (): PdcDataStore {
        return PdcClientApp.mDataStore
    }

    /**
     *
     */
    public logout (): void {
        PdcSession.logout()
    }
}

/**
 *
 */
export { PdcClientApp }
/**
 *
 */
export type { PdcAppConfig, PdcClientState }
