import React from 'react'
import Typography from 'typography'
import { IntegrationDetails } from 'my-pdc-integration'
import { Button } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import Divider from '@material-ui/core/Divider'
import ZohoIcon from '../SVG/Zoho'
import Arrow from '../SVG/Arrow'
import { SignOutComponent } from '../SignOut/index'

const useStyles = makeStyles(styles)

const zohoIntegrationBtnText = 'Integrate with Zoho CRM'

/**
 *
 */
export function IntegrationComponent (props: {details: IntegrationDetails}): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    /**
     *
     */
    const clickHandler = () => {
        const uri = props.details.redirectURI
        window.open(uri, '_blank')
    }

    return (
        <div className={classes.integrationScreenRoot}>
            <div className={'logos'}>
                <img src={(theme as Record<string, any>).icons?.identityAreaLogo} alt="pdc logo" style={{ width: '56px' }} />
                <Divider className={'divider'} orientation="vertical" flexItem />
                <ZohoIcon />
            </div>
            <div className={'zoho-integration-text'}>
                <Typography className='zoho-details' variant='body2' remoteConfigID='web_phone_integrate_zoho'>
                    To integrate with zoho CRM, click the button below and follow the instructions to authorize your Phone.com account.
                </Typography>
            </div>
            <div className={'zoho-integration-btn-root'}>
                <Button onClick={clickHandler} variant='contained' color='primary' size='large' endIcon={<Arrow />} className={'zoho-integration-btn'}>
                    <Typography className='zoho-integration-btn-text'>{zohoIntegrationBtnText}</Typography>
                </Button>
            </div>
            <div className={'sign-out-btn-root'}>
                <SignOutComponent />
            </div>
        </div>
    )
}
