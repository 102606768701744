import React from 'react'

/**
 *
 */
const Arrow = (props: Record<string, any>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={19}
            height={18}
            fill="none"
            {...props}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M10.1 13.721a.75.75 0 0 0 1.057.01L15.2 9.785a.75.75 0 0 0 0-1.073L11.157 4.77a.75.75 0 0 0-1.056.009l-.326.328a.75.75 0 0 0 .003 1.058l2.372 2.37H3.964a.714.714 0 0 0 0 1.429h8.186l-2.372 2.371a.75.75 0 0 0-.003 1.058l.325.328Z"
                clipRule="evenodd"
            />
        </svg>
    )
}

export default Arrow
