/* eslint-disable camelcase */
const RemoteConfigDefaults = {
    messages_tab_title: 'Messages',
    meetings_tab_title: 'Meetings',
    search_left_nav_option_label: 'Search Messages',
    search_text_feild_label: 'Search Messages',
    menu_options_question: 'What are your menu options and menu actions?',
    custom_holidays_use_once_label: 'Only Once',
    custom_holidays_repeat_yearly_label: 'Every year',
    configure_number_during_business_hours_question: 'What should happen when a call arrives during business hours?',
    configure_number_outside_business_hours_question: 'What should happen when a call arrives after business hours?',
    configure_number_during_holiday_hours_question: 'What should happen when a call comes in but you are closed for holidays?',
    configure_number_during_lunch_hours_question: 'What should happen when a call arrives during lunch break?',
    configure_number_general_question: 'What should happen when a call arrives?',
    configure_number_other_type_label: 'Voice line',
    configure_number_fax_type_label: 'Fax line',
    configure_number_main_company_type_label: 'Main company number',
    configure_number_user_direct_type_label: 'User direct number',
    bh_business_hours_heading_label: 'Business hours',
    bh_holidays_heading_label: 'Holidays',
    bh_custom_holidays_heading_label: 'Other Custom Hours',
    emergency_address_info_message_1: 'The address below helps determine where to route your call when you dial 911 from your phone. In areas that support E911, this address will appear on the emergency dispatcher computer screen.',
    emergency_address_info_message_2: 'Instructions for testing your E911 settings:',
    emergency_address_info_message_3: 'Please provide a complete and accurate E911 address, and update it when you move your phone to another location, even temporarily.',
    emergency_address_modal_title: 'Confirm the emergency address for E911 dialing',
    account_notifications_billing_email_title: 'Billing CC email',
    account_notifications_billing_email_description: 'Who should we send billing statements and billing related to, besides sending it to your admin user email?',
    account_notifications_administrative_email_title: 'Administrative CC email',
    account_notifications_administrative_email_description: 'If we need to send you important alerts, such as someone called 911 on your account, or your account is about to be suspended, besides your admin user email, which email should we also CC?',
    configure_number_main_number_already_set_tooltip: 'At this time, you can only have one main company number. You can move your existing one to "Custom voice line" first if you want to make this one your main company number.',
    users_sip_credentials_modal_info_text: 'Use the settings below to manually configure a phone for this user.',
    users_sip_credentials_modal_button_text: 'Got it!',
    user_settings_caller_id_change_confirm_message: 'If you change your default caller ID, it will also change in your mobile app, desktop app, and desk phone (if you have one).',
    user_settings_caller_id_change_confirm_button: 'Update caller ID',
    user_settings_caller_id_change_reject_button: 'Cancel',
    user_settings_caller_id_change_title: 'Update caller ID?',
    account_notifications_save_error_message: 'Oops, error occured while saving. Please try again.',
    configure_account_notifications_invalid_email_message: 'Invalid email',
    configure_account_notifications_already_used_email_message: 'Email already used',
    configure_number_save_failure_prompt_message: 'Oops, something failed while saving the configuration.',
    configure_number_number_type_title: 'Number settings',
    configure_number_number_rules_title: 'Call handling',
    configure_number_welcome_message_toggle_label: 'Play a welcome message first',
    configure_number_holidays_welcome_message_toggle_label: 'Should we greet callers with a welcome message during holidays?',
    configure_number_lunch_break_welcome_message_toggle_label: 'Should we greet callers with a welcome message during lunch hours?',
    configure_number_after_hours_welcome_message_toggle_label: 'Should we greet callers with a welcome message outside of business hours?',
    configure_number_open_hours_welcome_message_toggle_label: 'Should we greet callers with a welcome message during business hours?',
    configure_number_welcome_message_virtual_answer_text: 'AI-Connect will handle your calls as they come in.',
    configure_number_welcome_message_header_text: 'Welcome message',
    configure_number_call_handling_header_text: 'Call handling',
    configure_number_ring_users_header_text: 'Ring users',
    configure_number_forward_external_header_text: 'Forward to a number',
    configure_number_forward_external_title: 'What number should we send your calls to?',
    configure_number_forward_external_modal_title: 'Forward number options',
    configure_number_forward_external_modal_caller_id_text: 'What Caller ID should we display?',
    configure_number_forward_external_modal_voice_tag_text: 'Voice tag',
    configure_number_forward_external_modal_screening_label: 'Call screening',
    configure_number_forward_no_answer_header_text: 'No answer',
    configure_number_voicemail_header_text: 'Send to voicemail',
    configure_number_assign_messages_header_text: 'Text messages',
    configure_number_ring_users_message: '',
    configure_number_no_answer_message: 'What should we do if no one picks up?',
    configure_number_menu_header_text: 'Use a menu',
    configure_number_menu_welcome_message: 'What will be the message played to the caller?',
    configure_number_menu_options_keypress_label: 'Press',
    configure_number_menu_options_assign_field_label: 'Associate calls to inbox',
    configure_number_menu_options_users_field_label: 'Select users',
    configure_number_menu_timeout_header_text: 'Nothing selected',
    configure_number_menu_timeout_forward_message: 'Which user or group of users should we ring?',
    configure_number_menu_timeout_voicemail_message: 'Whose inbox should we drop the voicemail to?',
    configure_number_menu_timout_message: 'What should we do if the caller does not enter any menu option?',
    configure_number_use_company_bussiness_hours_toggle_text: 'Use company business hours',
    configure_number_same_as_after_hours_text: 'Same as after hours',
    configure_number_open_hours_tab_text: 'Open hours',
    configure_number_after_hours_tab_text: 'After hours',
    configure_number_holidays_tab_text: 'During holidays',
    configure_number_lunch_break_tab_text: 'Lunch break',
    configure_number_live_reception_plan_header_text: 'Choose live reception plan',
    configure_number_live_reception_notes_header_text: 'Call handling notes',
    configure_number_live_reception_notes_infotip: 'Example: When transferring a call, always conduct using "warm" transfer. If no one answers, direct caller to recipient\'s voicemail.',
    configure_number_live_reception_configuration_header_text: 'Configure live receptionist',
    configure_number_live_reception_configure_modal_title: 'Configure Receptionist Serivce',
    configure_number_live_reception_confirm_charges: 'Your next invoice will include a prorated amount based on the number of days left in your billing cycle. Taxes and fees may apply.',
    configure_number_receptionist_info: 'Our Live Receptionist service is perfect for giving your callers a white glove experience with US-based professionals who can answer your calls and guide callers to wherever you choose (e.g. voicemail, user extensions). They can also take messages or schedule appointments.',
    configure_number_receptionist_info_2: "If you proceed with this option, a greeting with your company name will be created for you.  Calles will be able to ask for any of your active users, and they will be announced to the recipient.  If the call is rejected or unanswered, it will go to the user's Phone.com voicemail. You can edit these settings after you save your script.",
    configure_number_receptionist_info_virtual: "The AI-Connect is an AI-powered technology ideal for greeting customers and routing calls to the right person or team. If you proceed with this option, a greeting with your company name will be created for you.  Calles will be able to ask for any of your active users, and they will be announced to the recipient.  If the call is rejected or unanswered, it will go to the user's Phone.com voicemail. You can edit these settings after you save your script.",
    configure_number_receptionist_info_virtual_2: 'The Virtual receptionist service is available 24x7.',
    configure_number_virtual_receptionist_infotip: 'The AI-Connect is an AI-powered technology ideal for greeting customers and routing calls to the right person or team. If you proceed with this option, a greeting with your company name will be created for you. Callees will be able to ask for any of your active users, and they will be announced to the recipient. If the call is rejected or unanswered, it will go to the user\'s Phone.com voicemail. You can edit these settings after you save your script. The cost for this service is $19.99/mo plus applicable taxes.',
    configure_number_live_reception_option_info: 'If you need more than 50 minutes a month, you can change to a plan with more minutes after you create your script.',
    configure_number_live_receptionist_greeting: 'I\'m happy to connect your call. Which person or team would you like to reach today?',
    configure_number_virtual_receptionist_greeting: 'Thank you for calling',
    configure_number_disable_script_title: 'You are about to disable your receptionist script.',
    configure_number_disable_script_content: 'Are you sure you wish to continue?',
    user_profile_caller_id_field_label: 'Preferred number to call from',
    company_setup_general_settings_circle_label: 'Company setup',
    company_setup_general_settings_title: 'Company setup',
    company_setup_general_settings_subtitle: 'Here we will make sure all your general settings are configured correctly',
    company_setup_general_settings_bottom_navigation_text: 'You can always change these settings later by visiting General Settings, found in the Company section of your Configure tools.',
    company_setup_business_hours_circle_label: 'Business hours',
    company_setup_business_hours_title: 'Business hours',
    company_setup_business_hours_subtitle: 'Let\'s set up your hours of operation',
    company_setup_business_hours_bottom_navigation_text: 'You can always change these settings later by visiting Business Hours in the Company section of Configure.',
    company_setup_user_setup_circle_label: 'User setup',
    company_setup_user_setup_title: 'User setup',
    company_setup_user_setup_subtitle: 'Let\'s configure the users in your account',
    company_setup_user_setup_bottom_navigation_text: 'Pro and plus users are automatically given a direct number. Anyone calling these numbers will ring each user directly. You can also add a direct number to basic users for an additional monthly charge.',
    company_setup_call_handling_circle_label: 'Call handling',
    company_setup_call_handling_title: 'Call handling',
    company_setup_call_handling_subtitle: 'Now, let\'s set up how incoming calls will be handled by your company main number',
    company_setup_call_handling_bottom_navigation_text: 'If you are using custom business hours, use the tabs to change between open and after hours.',
    company_setup_saving_message: 'Please wait while we set up your account...',
    company_setup_saved_main_message: 'You\'ve successfully set up your account!',
    company_setup_saved_secondary_message: 'We\'ll now redirect you to my.phone.com, where you will be able to make and receive calls...',
    company_setup_timezone_title: 'Timezone',
    company_setup_timezone_name_message: 'What is the main timezone for your business?',
    company_setup_caller_id_title: 'Caller ID name',
    company_setup_caller_id_name_message: 'What Caller ID name should we display to others when you call out from your main number?',
    company_setup_voicemail_title: 'Company voicemail',
    company_setup_voicemail_name_message: 'What message do you want to play for your callers before leaving a voicemail?',
    company_setup_hold_music_title: 'Hold music',
    company_setup_hold_music_name_message: 'What should callers hear while on hold or waiting to be transferred?',
    company_setup_voicemail_tts_text: 'Text to speech',
    company_setup_voicemail_record_text: 'Record a greeting',
    company_setup_voicemail_upload_text: 'Upload an audio file',
    company_setup_hold_music_record_text: 'Record a greeting',
    company_setup_hold_music_upload_text: 'Upload an audio file',
    company_setup_hold_music_choose_file: 'Choose from a list',
    configure_number_welcome_message_toggle_description: 'Play a welcome message first',
    offline_bar_text: 'You\'re offline. Check your connection',
    configure_number_bottom_navigation_text: 'Some text here',
    configure_number_number_type_question_text: 'This number is a:',
    configure_number_welcome_greeting_tts_text: 'Read a text',
    configure_number_welcome_greeting_record_text: 'Record a message',
    configure_number_welcome_greeting_upload_text: 'Upload a file',
    configure_number_menu_add_option_text: 'Add a menu option',
    configure_number_forward_second_timeout_voicemail_message: 'If no answer, send to voicemail of:',
    configure_number_inactive_response: 'The phone number is not active',
    configure_number_saving_progress_message: 'Please wait while we\'re saving your call handling rules',
    csw_splash_thank_you_message: 'Thank you for choosing Phone.com',
    csw_splash_info_message: 'Let’s get you and your company set up.',
    csw_splash_button_text: 'Get started',
    music_on_hold_sub_text: 'What should callers hear while on hold or waiting during a transfer?',
    edit_profile_footer_text: 'Here you can configure different settings for a user’s profile.',
    configure_users_manage_users_basic_plan_1: 'Note that the phone will no longer be operational if it is unassigned.',
    configure_users_manage_users_basic_plan_2: 'If you wish to downgrade this user, click Phones on the left navigation menu, locate the phone, unassign it from the user, and then return here to downgrade.',
    configure_users_manage_users_basic_plan_3: 'This user has a phone assigned and cannot be downgraded to Basic.',
    csw_user_step_invited_email_tooltip: 'User already invited.',
    transfer_number_intro_video_description: 'Watch this short video before proceeding with the Transfer number process.',
    transfer_number_intro_video_bottom_navigation_text_1_bold: 'Any rejections on transfers will delay the process by at least 5 business days, so make sure to read through these guidelines carefully.',
    transfer_number_intro_video_bottom_navigation_text_2: 'For more details on reasons for transfer request rejections, refer to our article:',
    transfer_number_checklist_preamble: 'Before moving forward on your transfer request, please make sure you meet the following prerequisites.',
    transfer_number_checklist_heading_1: 'The number(s) you are transferring have no pending orders',
    transfer_number_checklist_heading_2: 'The account with your current provider is still active',
    transfer_number_checklist_rejection_warning: 'Any rejections on transfers will delay the process by at least 5 business days, so make sure to read through these guidelines carefully. For more details on reasons for transfer request rejections, refer to our article:',
    transfer_number_checklist_rejection_warning_link_href: 'https://www.phone.com/phone-support/porting/porting-request-denied/',
    transfer_number_checklist_rejection_warning_link_text: 'Transfer Number Rejections',
    transfer_number_checklist_text_1: 'Your current service provider may reject the transfer if you have orders for additional services, or if your account is being cancelled. To avoid delays, make sure to cancel any orders you might have on your account.',
    transfer_number_checklist_text_2: 'Additional telephony services from your number, such as hunt groups, Centrex, call forwarding, among others are disabled.',
    transfer_number_checklist_video_link_href: 'https://www.phone.com/wp-content/uploads/2021/06/Phone.com-DS-FN.mp4',
    transfer_number_checklist_video_watch_text_1: 'To proceed with your number transfer watch this short video or',
    transfer_number_checklist_video_watch_text_2: 'read the script',
    transfer_number_checklist_video_watch_text_3: 'to learn how it works.',
    transfer_number_checklist_video_script_title: 'Transfer Your Number to Phone.com',
    transfer_number_checklist_video_script_content_p_1: 'Congratulations on your decision to transfer your phone number to Phone.com! The phone number transfer process is a little more complicated than most people realize. In the biz it’s called “number porting.”',
    transfer_number_checklist_video_script_content_p_2: 'While some other vendors charge for porting, with Phone.com, we do all the work at no cost to you. But there are a few things you should know.',
    transfer_number_checklist_video_script_content_p_3: 'The porting process usually takes between 10 and 15 days, but may take longer depending on your current provider. Transferring a phone number requires the cooperation of Phone.com and your existing provider. We’ll make the request and they will release the phone number to us. Well, they will usually release the phone number to us.',
    transfer_number_checklist_video_script_content_p_4: 'Most US local and toll-free numbers can be ported, but there are some limitations. Your current carrier may reject the transfer if:',
    transfer_number_checklist_video_script_content_bullet_1: 'The contact information we provide does not match their records, or',
    transfer_number_checklist_video_script_content_bullet_2: 'You are under a contractual obligation to them related to the number, or',
    transfer_number_checklist_video_script_content_bullet_3: 'You have a pending order related to the number.',
    transfer_number_checklist_video_script_content_p_5_1: 'It is important that we verify that you have the right to transfer the number, so we will require a bill from your current provider or a customer service record. For Toll-free numbers, we’ll also need a signed letter of authorization. Your phone number must be active at the time of transfer, so',
    transfer_number_checklist_video_script_content_p_5_2_bold: 'don\'t cancel with your old provider yet!',
    transfer_number_checklist_video_script_content_p_5_3: 'We will tell you it is time to do so.',
    transfer_number_checklist_video_script_content_p_6: 'When you signed up for Phone.com, you were given a temporary number. You can use your current provider’s service to forward your existing phone number to the temporary one so you can start using your Phone.com services right away.',
    transfer_number_checklist_video_script_content_p_7: 'We will keep you informed about the progress of your transfer and will let you know when we have a commitment date from your current provider.',
    transfer_number_checklist_video_script_content_p_8: 'Thanks again for choosing Phone.com! We look forward to serving you.',
    transfer_number_loa_bottom_nav_text: 'Electronic authorization is the document that allows us to legally submit a request to transfer a number on your behalf. Read thoroughly, accept and submit.',
    transfer_number_ea_bottom_nav_text: 'Electronic authorization is the document that allows us to legally submit a request to transfer a number on your behalf. Read thoroughly, accept and submit.',
    transfer_number_loa_subtitle: 'Review the following information. Make any changes necessary, or complete this setup by hitting ‘Submit’.',
    transfer_number_loa_title: 'Electronic authorization',
    transfer_number_ea_title: 'Electronic authorization',
    transfer_number_number_assignment_bottom_navigation_text_1: 'Temporary numbers will allow you to use the service while your number is being transferred.',
    transfer_number_number_assignment_bottom_navigation_text_2_bold: 'The number you set as temporary will be removed from your account when the transfer is completed.',
    transfer_number_number_assignment_title: 'Number assignment',
    transfer_number_number_assignment_subtitle: 'Here you can assign a temporary number to the number(s) you are transferring.',
    transfer_number_number_assignment_confirm_text_1: 'The number of auto-assign numbers you selected will result in the additional monthly charges shown above.',
    transfer_number_number_assignment_confirm_text_2: 'If you are on annual, you will be charged for the remaining of the billing period.',
    transfer_number_number_assignment_confirm_text_3: 'Would you like to continue?',
    transfer_number_number_info_billing_number: '* Main billing number',
    transfer_number_number_info_billing_number_subtitle: 'What is the main billing telephone number on your current provider\'s account?',
    transfer_number_number_info_footer: 'Be sure to enter the correct billing telephone number, as well as the numbers you are transferring — incorrect telephone numbers are a common reason for transfer rejections.',
    transfer_number_number_info_documents_title: 'Upload documents',
    transfer_number_number_info_latest_bill: 'Most recent phone bill',
    transfer_number_number_info_latest_bill_subtitle: 'Provide your most recent phone bill.',
    transfer_number_number_info_latest_bill_infotip: 'Monthly bill should be within the last 30 days. If annual, the bill should be active.',
    transfer_number_number_info_customer_service_record: 'Customer service record',
    transfer_number_number_info_customer_service_record_infotip: 'A CSR (Customer Service Record) can be obtained by your current phone service provider. Providing a CSR will ensure that Phone.com has the correct and necessary information to complete the number transfer. If your current service provider is unable to provide a CSR, please make sure to provide the most recent phone bill.',
    transfer_number_number_info_requested_completion_date: 'Requested completion date',
    transfer_number_number_info_requested_completion_date_infotip: 'This is the date your number(s) will transfer to Phone.com',
    transfer_number_number_info_secondary_title: 'Now, we’ll need the information that pertains to the number you are transferring.',
    transfer_number_number_info_service_provider: '* Service provider',
    transfer_number_number_info_service_provider_subtitle: 'Who is your current phone service provider/carrier?',
    transfer_number_number_info_transfer_numbers: '* Number(s) to transfer',
    transfer_number_number_info_transfer_numbers_subtitle: 'Enter up to 15 numbers you wish to transfer to your Phone.com account.',
    transfer_number_number_info_file_on_record: 'File on record',
    transfer_number_number_info_no_file_on_record: 'There is no service record currently on file.',
    transfer_number_number_info_latest_bill_required: 'A recent phone bill is required',
    transfer_number_number_info_latest_bill_required_subtext: 'If a recent bill is not available, please provide a screenshot of your account to prove ownership.',
    tw_account_information_step_account_number_subtitle: 'What\'s the account number shown on your phone bill?',
    tw_account_information_step_account_number_title: 'Account number:',
    tw_account_information_step_account_number_tooltip: 'This may be different from your phone number.',
    tw_account_information_step_account_pin_subtitle: 'What\'s the PIN number for your account?',
    tw_account_information_step_account_pin_title: 'Transfer Port Out PIN',
    tw_account_information_step_address_subtitle: 'If you do not know your PIN please leave it blank. If a PIN is required, we will communicate with you via email.',
    tw_account_information_step_address_title: 'Service address',
    tw_account_information_step_autorized_person_subtitle: 'Who\'s the person that\'s authorized to make changes on the account, as shown on your phone bill?',
    tw_account_information_step_autorized_person_title: 'Authorized person on account',
    tw_account_information_step_company_name_subtitle: 'What\'s the exact company name shown on your phone bill?',
    tw_account_information_step_company_name_title: '* Company name',
    tw_account_information_step_subtitle: 'Let\'s capture the account information of the number you\'ll be transferring to Phone.com.',
    tw_account_information_step_title: 'Account Information',
    tw_account_information_step_footer: 'Be sure to confirm the account\'s authorized name matches the owner\'s name for the numbers you are transferring and enter the exact address as shown on your phone bill.',
    quick_add_number_widget_message: 'Additional monthly charges may apply.',
    configure_company_business_hours_when_open: 'You can set company business hours if you want your main company number to handle calls differently when your open vs. when you are closed, or on holiday. For example you can ring your users during the working day, but send calls to the company inbox after hours, to be looked at the next business day. Ensure your  timezone is set in your company\'s general settings before using business hours.',
    configure_company_business_hours_national_holidays: 'If you are closed or have custom hours during national holidays, identify those days and hours here. On the Main Company Number setup page, you can set instructions for how to handle calls during closed holiday hours. For example, you can play a holiday welcome message and send the calls to the company inbox voicemail.',
    configure_company_business_hours_other_custom_days: 'If there are other days that your company is closed or has special business hours, you can identify them here. The after hours call handling rules you set will apply to these closed hours as well. For example, if your company observes a special company event, you can identify it here.',
    configure_company_business_hours_closed_for_lunch: 'If you want calls handled differently during your lunch period, you can lunch hours here. For example, you can send calls during this period to voicemail and mention that you are on your lunch break.',
    configure_caller_id_disclamer: 'Your Caller ID name can be up to 15 characters, including letters, numbers, spaces, and commas. It may take up to 30 days for changes you make to be fully registered.',
    configure_numbers_add_review_order: 'Your account includes 1 number per user. Each additional number beyond your maximum allocation is charged monthly.',
    configure_numbers_transfer_requests_status_review: 'Under review:  The transfer request has been received and is being reviewed by a porting specialist.',
    configure_numbers_transfer_requests_status_info: 'Requires additional information: the transfer request has been reviewed and it has been determined that there is either missing or incorrect information. Check your email for further instructions.',
    configure_numbers_transfer_requests_status_provider: 'Waiting on provider: The transfer request has been reviewed and approved by a porting specialist. At this point, we are waiting for your service provider to finalize the transfer request.',
    configure_numbers_transfer_requests_cob: 'All numbers you are transferring require a Copy of bill to verify information about the number you are transferring. Make sure to upload a copy of your latest  bill for each transfer request.',
    configure_numbers_transfer_requests_loa: 'All toll free numbers require a signed LOA or Letter of Authorization. You received an email containing the LOA template after submitting this transfer. Fill out the LOA, sign it, and upload it here.',
    configure_numbers_transfer_requests_csr: 'A CSR (Customer Service Record) can be obtained by your current phone service provider. Providing a CSR will ensure that Phone.com has the correct and necessary information to complete the number transfer. If your current service provider is unable to provide a CSR, please make sure to provide the most recent phone bill.',
    configure_numbers_submit_transfer_transfer_billing_number: 'The number you are transferring might not be the same as your billing number, so make sure to review your bill to confirm. If you are unsure, contact your current service provider.',
    configure_numbers_submit_transfer_latest_bill: 'If you don\'t upload the copy of your bill now, the transfer process will take longer.',
    configure_tw_account_information_step_address_tooltip: 'The service address is where the service for the number to transfer is currently being used. This may be different than your billing address. * ​You cannot use a PO Box Address.',
    configure_tw_account_information_step_wireless_tooltip: 'In most cases, your wireless number will continue to receive SMS during the entire transfer process, however you might experience some issues receiving messages. You should not expect any downtime or loss of service associated with voice calls.',
    configure_numbers_submit_transfer_temporary_number: '​Temporary numbers allow you to use the service while your transfer is being processed. When the transfer is completed, the number you select here will be removed from your account and all its settings will be copied to your transferred number.',
    configure_numbers_submit_transfer_is_additional_charges: '? ? ?',
    configure_numbers_submit_transfer_auto_assign_number: 'This option will automatically assign a new number to your account.',
    configure_company_general_settings_timezone: 'The timezone is used to determine whether you are open or closed based on your business hours. Make sure it is set correctly.',
    configure_phones_manage_phones_e911_address: 'The address below helps determine where to route your calls when you dial 911 from your phone. If you see a red icon, the number shown equals the amount of phones that are missing an emergency address. To remove the red icons, make sure to add an emergency address to all phones.',
    configure_phones_manage_phones_e911_e911_title: 'If you do not provide us with a valid US address, Phone.com CANNOT provide reliable 911 services.',
    pro_tip_for_text_to_speach: 'Pro tip: make sure to use punctuation like dots ( . ) to end sentences, and commas ( , ) to put a pause, so it sounds more natural!',
    configure_numbers_edit_number_call_handling_chr_options_ring_users: 'Ring users: ring multiple users simultaneously.',
    configure_numbers_edit_number_call_handling_chr_options_use_menu: '​Use a menu: allows you to give callers the option to dial numbers and be routed differently.',
    configure_numbers_edit_number_call_handling_chr_options_send_voicemail: 'Send to voicemail: sends callers automatically to a voicemail you define.',
    configure_numbers_edit_number_call_handling_chr_options_forward_number: 'Forward to a number: redirects calls to another phone number of your choosing.​',
    configure_numbers_edit_number_call_handling_chr_options_receptionist: 'Use Live Answer-Connect: have US-based professionals answer and direct your calls. (This is a premium service with a monthly charge.)',
    configure_numbers_edit_number_call_handling_chr_options_intelligent_answer: 'Use AI-Connect: Use our AI-powered voice-enabled tool to allow callers to ask for the person or department they would like to reach.',
    configure_number_intelligent_answer_bot_option_text: 'Use AI-Connect',
    configure_number_live_receptionist_plus_option_text: 'Use Live Answer-Connect',
    configure_number_iab_promo_1: 'The AI-Connect is an AI-powered technology ideal for greeting customers and routing calls to the right person or team. Callers will be able to ask for any of your active users, and they will be announced to the recipient.',
    configure_number_iab_promo_2: 'The AI-Connect is just an additional $19.99 per month billed monthly or annually along with the rest of your account.',
    configure_call_handling_menu_message: 'Use this message to explain the menu options to the caller.The message will repeat 3 times with 10 second intervals between them before the "No option dialed" destination is triggered.',
    fax_download_receipt_menu_text: 'Download Receipt',
    virtual_selector_nothing_found_text: 'no results found',
    leave_page_active_call_modal_cancel_text: 'Reject',
    leave_page_active_call_modal_confirm_text: 'Confirm',
    leave_page_active_call_modal_question: 'Are you sure?',
    leave_page_active_call_modal_description: 'Leaving the page will drop any active calls',
    top_bar_do_not_disturb_enabled_tooltip_text: 'Turn off do not disturb',
    top_bar_do_not_disturb_disabled_tooltip_text: 'Turn on do not disturb',
    disabled_call_buttons_tooltip_text_virtual_extension: 'Outbound calling is not available for this extension type',
    signup_error_boundary_title: 'Something went wrong',
    signup_error_boundary_description: 'An unexpected problem occured while displaying this page.',
    signup_error_boundary_contact_info: 'If the problem persists please contact sales at 844.746.6312',
    signup_number_options_title: 'Which phone number is right for you?',
    signup_number_options_description: 'We’ll help you pick your main company phone number now. Additional numbers can be added after your account is created.',
    signup_number_options_local: 'Local',
    signup_number_options_toll_free: 'Toll free numbers',
    signup_number_options_custom: 'Custom numbers',
    signup_local_numbers_title: 'Reserve your local number',
    signup_local_numbers_enter_an_area_code: 'Enter an area code from US or Canada',
    signup_local_numbers_no_numbers: 'No numbers were found in this area code',
    signup_toll_free_numbers_title: 'Reserve your toll-free number',
    signup_toll_free_numbers_no_numbers: 'There are currently no toll-free numbers available.',
    signup_toll_free_numbers_footer: 'All 800 numbers have a $15+ one-time fee and all 888 numbers have a $10+ one-time fee. Other toll free numbers may have a one-time fee of up to $49.',
    signup_custom_numbers_title: 'Reserve your custom number',
    signup_custom_numbers_footer: 'Custom numbers have a one-time acquisition fee. This will not increase your monthly fee.',
    signup_custom_numbers_search_description: 'Find numbers that contain:',
    signup_custom_numbers_no_numbers: 'We\'re sorry we could not find any numbers containing that text.',
    signup_custom_numbers_search_again: 'Search again',
    signup_transfer_number_description: 'We are happy that you’ve decided to transfer your number to Phone.com. To transfer a number, complete signup using this temporary number and we will email you a link to continue the transfer process. You can forward your existing number to this temporary number to start using our service.',
    signup_transfer_number_footer: 'Note: In order to transfer your number to Phone.com, it must be active with your current carrier. Don’t cancel service for this number with the other carrier until we confirm that the transfer is complete.',
    signup_number_search_results_recommended_number: 'We recommend:',
    signup_number_search_results_or_choose: 'Or choose from these great numbers',
    signup_number_search_results_choose: 'Choose from these great numbers',
    signup_order_summary_heading: 'Order summary',
    signup_order_summary_billing_cycle: 'Billing cycle',
    signup_order_summary_billing_cycle_monthly: 'Monthly',
    signup_order_summary_billing_cycle_annually: 'Annually',
    signup_order_summary_billing_cycle_annual_savings: 'Save 15% by choosing annual billing',
    signup_order_summary_taxes_shown_later: 'Taxes will be shown when address has been provided',
    signup_order_summary_taxes_tooltip: 'As a phone service provider, we are legally required to charge communication taxes, in addition to local sales tax. Tax rates may change from time to time.',
    signup_creating_your_account_title: 'Creating your account...',
    signup_creating_your_account_footer: 'This will take a few minutes...',
    signup_account_created_title: 'Your account has been created',
    signup_account_created_footer: 'You will now be redirected to Phone.com\'s Configure app to establish some basic requirements and communication settings to help optimize your experience.',
    softphone_preferences_call_waiting_beep_primary_label: 'Call Waiting Beep',
    softphone_preferences_call_waiting_beep_secondary_label: 'Beep when getting a call\n during an active call',
    softphone_preferences_ringback_tone_primary_label: 'Ringback Tone',
    softphone_preferences_ringback_tone_secondary_label: 'Tone when placing a call',
    softphone_preferences_audio_sources_section_label: 'Audio sources',
    softphone_preferences_input_source_label: 'Microphone source',
    softphone_preferences_output_source_label: 'Speaker source',
    softphone_preferences_volume_control_label: 'Volume',
    softphone_preferences_ringtone_section_label: 'Ringtone',
    softphone_preferences_incoming_call_ringtone_label: 'Incoming call',
    softphone_preference_standard_tones_title: 'Standard Tones',
    new_calls_app_info_title: 'Phone.com\'s Dialer Beta Experience is Here',
    new_calls_app_info_subtitle: 'Get ready to enhance your calling experience with phone.com new dialler functionality',
    new_calls_app_info_confirm_button_text: 'Try Beta Now',
    new_calls_app_info_screenshot_title_1: 'New Dialer',
    new_calls_app_info_screenshot_subtitle_1: 'for easy calling',
    new_calls_app_info_screenshot_title_2: 'Improved',
    new_calls_app_info_screenshot_subtitle_2: 'calling experience',
    new_calls_app_info_screenshot_title_3: 'Multiple',
    new_calls_app_info_screenshot_subtitle_3: 'Callers in call',
    new_calls_app_info_screenshot_title_4: 'New Features',
    new_calls_app_info_screenshot_subtitle_4: 'to improve calling experience',
    new_calls_app_info_terms_and_condition_pretext: 'By joining the beta you are agreeing to the ',
    new_calls_app_info_terms_and_condition_text: 'terms and conditions',
    new_calls_app_terms_and_conditions_link: 'https://www.phone.com/general-terms/',
    new_calls_app_description_items: '["New Improved Calling","New Dialler Screen","Easy Calling Functions","Improved Conference Calling Experience","Blind & Attended Transfer Support","Ringtone Selection","Improved Teammate calling"]',
    receptionist_app_info_title: 'Phone.com\'s Receptionist Experience is Here',
    receptionist_app_info_subtitle: 'Get ready to enhance your calling experience with phone.com new receptionist functionality',
    softphone_calls: 'false',
    remote_config_feature_flag_not_enabled: 'false', // this is for a jest test
    remote_config_feature_flag_enabled: 'true', // this is for a jest test
    personal_settings_profile_dialer_beta_enable_label: 'Enroll in Phone.com\'s Dialer Beta Experience',
    personal_settings_profile_dialer_beta_disable_label: 'Enrolled in Phone.com\'s Dialer Beta Experience',
    personal_settings_profile_dialer_beta_enable_short_label: 'New dialer is disabled',
    personal_settings_profile_dialer_beta_disable_short_label: 'New dialer is enabled',
    new_calls_app_modal_close_title: 'Are you sure that you do not want to join the new calling experience?',
    more_services_tab_link: process?.env?.REACT_APP_MORE_SERVICES_TAB_LINK || 'https://reccp.pdc-partners.xyz/',
    user_settings_notifications_auto_reply_calls_placeholder: 'what ever',
    user_settings_notifications_auto_reply_messages_placeholder: 'what ever',
    user_settings_notifications_auto_reply_info_alert_text: '2432',
    user_settings_notifications_auto_reply_calls_label: '3432',
    user_settings_notifications_auto_reply_header_text: 'fdsfds',
    user_settings_notifications_auto_reply_messages_label: ' fsfds',
    user_settings_notifications_desktop_disable_info_text: 'fdsfds',
    user_settings_notifications_desktop_main_text: 'fdsfsd',
    user_settings_notifications_email_attach_fax_text: 'fdsfsd',
    user_settings_notifications_email_voicemail_audio_transcription_text: 'fdsfsd',
    user_settings_notifications_email_messages_faxes_voicemail_text: 'fdsfsd',
    user_settings_notifications_email_calls_text: 'fdsfsd',
    user_settings_notifications_desktop_faxes_text: 'fdsfsd',
    user_settings_notifications_desktop_messages_text: 'fdsfsd',
    user_settings_notifications_desktop_voicemails_text: 'fdsfsd',
    user_settings_notifications_desktop_calls_text: 'fdsfsd',
    user_settings_notifications_desktop_enable_info_text_2: 'fdsfsd',
    user_settings_notifications_desktop_enable_info_text: 'fdsfsd',
    sms_enrollment_title: 'Business SMS Enrollment',
    sms_enrollment_mobile_carriers_require: 'Mobile carriers are now requiring businesses to register their company information and how they are using SMS with The Campaign Registry (TCR) before they can send messages to US numbers.',
    sms_enrollment_before_your_registration: 'Before you start your registration, please collect important business information such as your federal business registration information, how you are using SMS, and how you are getting consent from recipients before messaging them.',
    sms_enrollment_learn_more: 'Learn more about US carrier-required registration and TCR fees.',
    sms_enrollment_get_started: 'Get Started',
    sms_enrollment_what_is_brand_title: 'What is a brand?',
    sms_enrollment_what_is_brand_description: 'A brand ID is a collection of information to identify the business sending an SMS. A brand identifies the company or entity the end user believes to be sending a message. For example, if an HVAC company, Acme HVAC, sends SMS maintenance reminders to its end customers using RingCentral, the brand would be identified as Acme HVAC. In most cases, a brand equals a legally registered company name.',
    sms_enrollment_what_is_campaign_title: 'What is a campaign?',
    sms_enrollment_what_is_campaign_description: "A campaign lets us know how you're using your phone numbers to send SMS. A company will typically have only one brand ID but may have several campaign IDs, depending on its SMS usage. For example, if the medical facility Acme Healthcare wants to send appointment reminders to its end customers via SMS, the campaign ID for that usage would be “appointment reminders.",
    sms_enrollment_assigning_numbers_title: 'Assigning numbers',
    sms_enrollment_assigning_numbers_description: "Once you have created a brand and a campaign, you will need to assign your numbers to the appropriate campaign. For most customers, you will assign all numbers using SMS, but if you're using it for automated campaign purposes you would assign those numbers to that campaign, while your other numbers would be assigned to your conversational campaign.",
    sms_enrollment_fee_will_be_charged: 'Enrollment fee will be charged for all new registrations',
    sms_enrollment_brand_enrollment_fee_label: 'One-time Brand enrollment fees',
    sms_enrollment_campaign_enrollment_fee_label: 'One-time Campaign enrollment fees',
    sms_enrollment_monthly_campaign_enrollment_fee_label: 'Monthly Campaign enrollment',
    sms_enrollment_total_fee_label: 'Total',
    sms_enrollment_brand_enrollment_fee: '$4 (non-refundable)',
    sms_enrollment_campaign_enrollment_fee: '$15 (non-refundable)',
    sms_enrollment_monthly_campaign_enrollment_fee: '$2',
    sms_enrollment_total_fee: '$2',
    sms_enrollment_business_enrollment_info: 'The fees charged by The Campaign Registry are non-refundable and set by the mobile carriers and the registrar. Phone.com does not profit from these fees.  We also cover the per-message TCR fees for our customers.',
    sms_enrollment_business_enrollment_payment_info: 'The fees charged by The Campaign Registry are non-refundable and set by the mobile carriers and the registrar. Phone.com does not profit from these fees.  We also cover the per-message TCR fees for our customers.',
    sms_enrollment_brand_name: 'Brand name:',
    sms_enrollment_enter_brand_name: 'Enter brand name:',
    sms_enrollment_legal_business_name: 'Legal business name:',
    sms_enrollment_enter_legal_business_name: 'Enter Legal business name',
    sms_enrollment_country_of_registration: 'Country of registration:',
    sms_enrollment_enter_country_registration: 'Enter Country of registration',
    sms_enrollment_federal_business_id: 'Federal business ID:',
    sms_enrollment_federal_business_subtitle: '(EIN for US, Corporation # for CA)',
    sms_enrollment_enter_federal_business_id: 'Enter Federal Business ID',
    sms_enrollment_business_type: 'Business type:',
    sms_enrollment_vertical: 'Vertical:',
    sms_enrollment_select_one: 'Select one',
    sms_enrollment_stock_symbol: 'Stock symbol:',
    sms_enrollment_stock_symbol_subtitle: '(For publicly traded company only)',
    sms_enrollment_enter_stock_symbol: 'Enter stock symbol (ticker symbol)',
    sms_enrollment_business: 'Business',
    sms_enrollment_information: 'Information',
    sms_enrollment_address_and_contact: 'Address & Contact',
    sms_enrollment_details: 'Payment Details',
    sms_enrollment_submit: 'Submit',
    sms_enrollment_invoice: 'Invoice',
    sms_enrollment_campaign: 'Campaign',
    sms_enrollment_campaign_name: 'Campaign name:',
    sms_enrollment_select_brand: 'Please select one:',
    sms_enrollment_campaign_name_placeholder: 'Name your campaign.',
    sms_enrollment_campaign_description: 'Campaign description:',
    sms_enrollment_campaign_description_subtitle: '(min of 40 characters, max of 1,024 characters)',
    sms_enrollment_campaign_description_placeholder: 'Describe how you will use SMS messaging.  For example, “I will send opted in contacts messages about products and services. I will send appointment reminders. I will let customers know the status of their project.”',
    sms_enrollment_business_information: 'Business Information',
    sms_enrollment_business_address_and_contact: 'Business Address & Contact',
    sms_enrollment_business_payment_confirmation: 'Business Payment Confirmation',
    sms_enrollment_business_address: 'Business address:',
    sms_enrollment_number_office: 'Number of Office/Plot/Building',
    sms_enrollment_enter_state: 'Enter State',
    sms_enrollment_enter_city: 'Enter City',
    sms_enrollment_postal_code: 'Postal Code',
    sms_enrollment_primary_business_phone: 'Primary business phone:',
    sms_enrollment_primary_business_phone_subtitle: '(For business verification purposes)',
    sms_enrollment_enter_primary_business_phone: 'Enter primary business phone',
    sms_enrollment_support_phone_number: 'Support phone number:',
    sms_enrollment_enter_support_phone_number: 'Enter support phone number',
    sms_enrollment_business_website: 'Business website:',
    sms_enrollment_enter_business_website: 'Optional',
    sms_enrollment_support_email: 'Support email:',
    sms_enrollment_enter_support_email: 'Enter support email',
    sms_enrollment_please_confirm_business_registration: 'Please confirm that EIN/Tax ID, Legal Name, and Address provided match your business registration. Mismatches may result in automatic rejection by TCR with a non-refundable fee.',
    sms_enrollment_please_confirm_campaign_registration: 'Please confirm that all the information provided match your business registration. Mismatches may result in automatic rejection by TCR with a non-refundable fee.',
    sms_enrollment_period: 'Period',
    sms_enrollment_charges_and_credits: 'Charges and credits',
    sms_enrollment_unit_price: 'Unit Price',
    sms_enrollment_quantity: 'Quantity',
    sms_enrollment_amount: 'Amount',
    sms_enrollment_one_time_charge: '(One time charge)',
    sms_enrollment_10ldc_sms_tcr_registration: '10DLC SMS TCR Registration - Standard brand',
    sms_enrollment_charges_discounts_prorates: 'Charges after discounts and proration',
    sms_enrollment_total_charges_discounts_prorates: 'Total charges after discounts and prorates:',
    sms_enrollment_taxes_discounts_prorates: 'Taxes after discounts and proration',
    sms_enrollment_state_local_tax_fees: 'State and local taxes and fees',
    sms_enrollment_total_taxes_and_fees: 'Total Taxes and Fees:',
    sms_enrollment_sub_total: 'Sub-total:',
    sms_enrollment_total_charges_credit_card: 'Total charges to Credit Card:',
    sms_enrollment_all_charges_appear_on_statement: 'All charges will appear on your statement this way: Phone.com. We’ll use this payment method on file for the account: credit card. Your payment information is encrypted and processed on a secure server.',
    sms_enrollment_excludes_account_credit_adjustments: '* Excludes Account Credit adjustments. Note, account credit will be applied to the entire statement, as opposed to individual Cost Centers.',
    sms_enrollment_business_acknowledgement: 'I understand that my use of the services is governed by the Terms of Service or Master Services Agreement, Accepted Use Policy, and SMS Message Policy.',
    sms_enrollment_add: 'Add',
    sms_enrollment_sms: 'SMS',
    sms_enrollment_usage_category: 'Usage Category',
    sms_enrollment_how_using_SMS: 'How are you using SMS?',
    sms_enrollment_message: 'Message',
    sms_enrollment_message_settings: 'Message Settings',
    sms_enrollment_sms_content: 'SMS Content',
    sms_enrollment_examples: 'Examples',
    sms_enrollment_sample_message: 'Sample messages',
    sms_enrollment_content: 'Content',
    sms_enrollment_policies: 'Policies',
    sms_enrollment_content_and_messaging: 'SMS content and messaging policies',
    sms_enrollment_contact: 'Contact',
    sms_enrollment_contact_information: 'Company information',
    sms_enrollment_preview: 'Preview',
    sms_enrollment_carriers_are_requiring: 'Carriers are requiring businesses to declare how they use SMS, or how they intend to use SMS. As part of this process, TCR and carriers also want to ensure businesses are aware of opt-in and opt-out requirements.',
    sms_enrollment_how_sms_service_used: 'How are you planning to use SMS services?',
    sms_enrollment_max_service: 'Please select up to 5 cases',
    sms_enrollment_most_common_use_case: 'Most common use cases',
    sms_enrollment_low_volume: 'Low Volume',
    sms_enrollment_customer_care: 'Customer Care or Conversational',
    sms_enrollment_account_notification: 'Account Notification',
    sms_enrollment_marketing: 'Marketing',
    sms_enrollment_other_use_case: 'Other use cases',
    sms_enrollment_2fa: '2FA (pin codes sent via SMS)',
    sms_enrollment_delivery_notification: 'Delivery Notification',
    sms_enrollment_higher_education: 'Higher Education',
    sms_enrollment_message_settings_alert: 'Please note, that depending on your use case you may or may not be utilizing opt-in keywords or an opt-in message. However, TCR requires these fields, and you should put in information that matches your current implementation, or what a future implementation may look like if you choose to use SMS in a way that requires these in the future. Failure to fill this out with industry recognized keywords and appropriate messaging will result in your registration being rejected.',
    sms_enrollment_opt_in_keywords: 'Opt-in keywords',
    sms_enrollment_opt_in_keywords_description: 'What words can a contact send to subscribe to messages from you?  ',
    sms_enrollment_opt_in_keywords_description_cont: 'Typical opt-in keywords are START or SUBSCRIBE.',
    sms_enrollment_opt_in_text_placeholder: 'START, SUBSCRIBE',
    sms_enrollment_opt_in_message: 'Opt-in message',
    sms_enrollment_opt_in_message_description: 'What message will the recipient get after opting in? There must be at least 20 characters and no more than 320.',
    sms_enrollment_opt_in_message_placeholder: 'Thank you for opting into SMS messages from {COMPANY_NAME}. To opt out at any time reply STOP.',
    sms_enrollment_opt_in_message_helperText: 'Replace {COMPANY_NAME} with your company name',
    sms_enrollment_opt_out_alert: 'Regardless of how you are using SMS, carriers require that you honor recipient requests to opt-out of receiving SMS messages. Please state which keywords you will honor (note STOP and UNSUBSCRIBE are required), as well as the message that will be sent to confirm receipt of the recipients opt-out request.',
    sms_enrollment_opt_out_keywords: 'Opt-out keywords',
    sms_enrollment_opt_out_keywords_description: 'What words can a contact send to stop getting messages from you?',
    sms_enrollment_opt_out_keywords_description_cont: 'The carriers require you to support STOP and UNSUBSCRIBE.',
    sms_enrollment_opt_out_keywords_helperText: 'Typical help keywords: HELP or INFO',
    sms_enrollment_opt_out_text_placeholder: 'STOP, UNSUBSCRIBE',
    sms_enrollment_opt_out_message: 'Opt-out message',
    sms_enrollment_opt_out_message_description: 'What message will the recipient get after opting out? There must be at least 20 characters and no more than 320.',
    sms_enrollment_opt_out_message_placeholder: 'You have successfully opted out of SMS messages from {COMPANY_NAME}, to opt back in at any time reply SUBSCRIBE',
    sms_enrollment_opt_out_message_helperText: 'Replace {COMPANY_NAME} with your company name',
    sms_enrollment_help_keywords: 'Help keywords',
    sms_enrollment_help_keywords_description: '(keywords a user can use to contact you or get more information about your business)',
    sms_enrollment_help_keywords_placeholder: 'HELP',
    sms_enrollment_help_message: 'Help message',
    sms_enrollment_help_message_description: '(what the recipient receives after sending a help keyword, min of 20 and max of 320 characters)',
    sms_enrollment_help_message_placeholder: 'Thank you for contacting {COMPANY_NAME}, we will be in touch with you soon to help.',
    sms_enrollment_help_alert: 'Please note, that depending on your use case you may or may not be utilizing HELP keywords or a HELP message. However, TCR requires these fields, and you should put in information that matches your current implementation, or what a future implementation may look like if you choose to use SMS in a way that requires these in the future.',
    sms_enrollment_sample_messages_alert: 'Sample messages are used by the carriers to identify your typical messages and flag those that are out of character. It is important to provide sample messages that accurately reflect those your contacts will receive. You should also include any links that you plan to send via SMS. Keep in mind that link shorteners such as bit.ly are not allowed',
    sms_enrollment_sample_messages_alert_2: 'Remember when sending links, link shorteners such as bit.ly are not allowed.Read more about sending links here.',
    sms_enrollment_sample_message_heading: 'Enter up to three (3) unique examples of text messages (minimum of 1)',
    sms_enrollment_sample_message_flow_subtitle: '(min of 40 characters, max of 1,024 characters)',
    sms_enrollment_sample_message_subtitle: '(min of 20 characters, max of 1,024 characters)',
    sms_enrollment_message_flow: 'Message flow',
    sms_enrollment_message_flow_subtitle: '(min of 40 characters, max of 1,024 characters)',
    sms_enrollment_message_flow_placeholder: 'Describe how your contacts typically enroll.  For example, you might say that contacts fill in a form on your website to opt-in to receiving messages, or that they verbally agree to receive messages over the phone.',
    sms_enrollment_message_one_title: 'Sample SMS message one',
    sms_enrollment_message_one_placeHolder: 'Sample messages should be unique to your brand.  For example, “Your [Company name] technician will arrive on Thursday at 3:00.”',
    sms_enrollment_message_two_placeHolder: 'Sample messages should be unique to your brand.  For example, “[Company name] is offering 20% off all services next month. Reply with LEARN MORE for more details.”',
    sms_enrollment_message_three_placeHolder: 'Sample messages should be unique to your brand.  For example, “[Company name] needs updated information to process your payment. Please contact us.”',
    sms_enrollment_message_two_title: 'Sample SMS message two',
    sms_enrollment_message_three_title: 'Sample SMS message three',
    sms_enrollment_policies_heading: 'Please confirm that you have read, understand, and agree to the following policies.',
    sms_enrollment_policies_subtitle: '(All fields required)',
    sms_enrollment_policies_checkbox_1: 'I understand that my use of the services is governed by the Terms of Service or Master Services Agreement, Acceptable Use Policy, and SMS Messaging Policy.',
    sms_enrollment_policies_checkbox_2: 'I represent that my use complies with CTIA A2P guidelines including, but not limited to, the prohibition of content related to sex (adult content), hate speech, alcohol, firearms, and tobacco.',
    sms_enrollment_policies_checkbox_3: 'The fees charged by The Campaign Registry are non-refundable and set by the mobile carriers and the registrar. Phone.com does not profit from these fees.  We also cover the per-message TCR fees for our customers.',
    sms_enrollment_contact_info_heading: 'By submitting this information, you attest that you are authorized to do so by the brand associated with this campaign and that all information is correct to the best of your knowledge.',
    sms_enrollment_name: 'Name',
    sms_enrollment_email: 'Email',
    sms_enrollment_contact_title: 'Title',
    sms_enrollment_enter_name: 'Enter name',
    sms_enrollment_enter_email: 'Enter email',
    sms_enrollment_campaign_enrollment_info: 'TCR fees are non-refundable and are set by mobile carriers and their registrar. Phone.com is not charging any additional fees or TCS registrationand pays a portionof the per-message carrier fees for our customer.',
    sms_enrollment_add_new_number: 'Add new number',
    sms_enrollment_brand_tab_title: 'Brands',
    sms_enrollment_campaign_tab_title: 'Campaign',
    sms_enrollment_did_tab_title: 'Numbers with texting',
    sms_enrollment_brand_id_column_name: 'Brand ID',
    sms_enrollment_brand_column_name: 'Brand Name',
    sms_enrollment_registered_column_name: 'Registered On',
    sms_enrollment_status_column_name: 'Status',
    sms_enrollment_add_brand_button: 'Add new brand',
    sms_enrollment_campaign_id_column: 'Campaign Identifier',
    sms_enrollment_add_new_campaign: 'Add new campaign',
    sms_enrollment_campaign_column_name: 'Campaign Name',
    sms_enrollment_sms_number_column_name: 'SMS Number',
    sms_enrollment_select_campaign: 'Select Campaign',
    sms_enrollment_number_step_1_title: 'Select campaign from the list',
    sms_enrollment_number_step_2_title: 'Add new number',
    sms_enrollment_number_step_1_secondaryLabel: 'Select Campaign',
    sms_enrollment_number_step_2_secondaryLabel: 'Select Number',
    sms_enrollment_number_campaign_step_instruction: 'Campaign identifiers are displayed below',
    sms_enrollment_number_select_step_instruction: 'Select the required number',
    sms_enrollment_keywords_error: 'Please use a comma, but no spaces between words.',
    sms_enrollment_keywords_error_required: 'Required field',
    sms_enrollment_ein_error: 'EIN must be exactly 9 digits',
    sms_enrollment_phone_number_helper_text: 'Please enter a valid phone number',
    sms_enrollment_valid_campaign_description_helper_text: 'Minimum of 40 characters',
    sms_enrollment_valid_opt_message_helper_text: 'Minimum of 20 characters',
    sms_enrollment_validating_address: 'Validating address...',
    sms_enrollment_invalid_address: 'Address cannot be validated. Please double check all address fields.',
    sms_enrollment_loading_payment: 'Loading payment details...',
    sms_enrollment_invliad_postal_code: 'Invalid postal code',
    sms_enrollment_brand_status_unknown: 'Unknown',
    sms_enrollment_brand_status_processing: 'Processing',
    sms_enrollment_brand_status_payment_failed: 'Payment Failed',
    sms_enrollment_brand_status_unverified: 'Rejected',
    sms_enrollment_brand_status_unverified_tooltip: 'Tax ID does not match with the company name or business type',
    sms_enrollment_enrollment_status_deleted_tooltip: 'This enrollment was deleted by a user',
    sms_enrollment_enrollment_status_pending_tooltip: 'Associated brand is pending review',
    sms_enrollment_brand_status_submitted: 'Pending Review',
    sms_enrollment_brand_status_verified: 'Approved',
    sms_enrollment_brand_status_creation_failure: 'Rejected',
    sms_enrollment_brand_status_deleted: 'Deleted',
    sms_enrollment_campaign_status_processing: 'Processing',
    sms_enrollment_campaign_status_payment_failed: 'Payment Failed',
    sms_enrollment_campaign_status_rejected: 'Rejected',
    sms_enrollment_campaign_status_pending: 'Pending Review',
    sms_enrollment_campaign_status_accepted: 'Approved',
    sms_enrollment_campaign_status_creation_failure: 'Rejected',
    sms_enrollment_campaign_status_unknown: 'Unknown',
    sms_enrollment_campaign_status_deleted: 'Deleted',
    sms_enrollment_support_email_helperText: 'Invalid email address',
    sms_enrollment_website_helperText: 'Enter a valid url',
    sms_enrollment_campaign_name_helperText: 'Minimum 3 characters, no special symbols allowed',
    sms_enrollment_campaign_contact_name_helperText: 'Minimum 2 characters, no special symbols allowed',
    sms_enrollment_campaign_contact_email_helperText: 'Invalid email address',
    sms_enrollment_banner_content: 'As part of U.S. wireless carrier efforts to combat spam and improve the delivery of legitimate text messages, a significant industry-wide change is occurring. Businesses that send text messages using local phone numbers must register with a 3rd party reputation authority called The Campaign Registry. The registration requirements apply to 10 DLC messaging (10-digit long codes), otherwise known as US local phone numbers.',
    sms_enrollment_banner_enroll_now_button: 'Enroll now',
    sms_enrollment_banner_ask_later_button: 'Ask later',
    sms_enrollment_banner_never_ask_again_button: 'Never ask again on this device',
    sms_enrollment_brand_website_placeholder: 'Optional',
    sms_enrollment_banner_enroll_as_admin: 'Log in as an Adminstrator to enroll',
    sms_enrollment_send_message_block: 'Your account is not enrolled for sending messages.',
    sms_enrollment_send_message_block_more_info: 'Click here to learn more.',
    sms_enrollment_campaign_delete_tooltip: 'Please delete all associated SMS number(s)',
    sms_enrollment_brand_delete_tooltip: 'Please delete all associated campaign(s)',
    sms_enrollment_info_link: 'https://phone-6860053.hs-sites.com/text-messaging-registration-instructions?ecid=ACsprvt40[…]Ut0DEhflts_d0&utm_content=264022621&utm_source=hs_email',
    sms_enrollment_notif_brand_submitted: 'Your brand has been submitted, and is under review. Once approved you can begin adding campaigns.',
    sms_enrollment_notif_brand_rejected: 'Your brand has been rejected.',
    sms_enrollment_notif_brand_approved: 'Your brand has been approved. You can now register campaigns with this brand.',
    sms_enrollment_notif_campaign_submitted: 'Your campaign has been submitted, and is under review. Once approved you can begin adding numbers.',
    sms_enrollment_notif_campaign_rejected: 'Your campaign has been rejected.',
    sms_enrollment_notif_campaign_approved: 'Your campaign has been approved. You can now register numbers with this campaign.',
    sms_enrollment_events: 'Events',
    sms_enrollment_invalid_business_addr: 'We could not validate the address you have entered, would you like to continue enrolling your brand?',
    sms_enrollment_company_details: 'Company Details',
    sms_enrollment_contact_details: 'Contact Details',
    sms_enrollment_brand_details: 'Brand Details',
    sms_enrollment_address_details: 'Address Details',
    sms_enrollment_other_details: 'Other Details',
    sms_enrollment_preview_vertical: 'Vertical',
    sms_enrollment_ein: 'EIN',
    sms_enrollment_preview_stock_symbol: 'Stock Symbol',
    sms_enrollment_country: 'Country',
    sms_enrollment_state: 'State',
    sms_enrollment_address: 'Address',
    sms_enrollment_city: 'City',
    sms_enrollment_website: 'Website',
    sms_enrollment_reference_id: 'Reference ID',
    sms_enrollment_business_phone: 'Business Phone',
    sms_enrollment_email_address: 'Email Address',
    sms_enrollment_preview_support_phone_number: 'Support Phone Number',
    sms_enrollment_campaign_id: 'Campaign ID',
    sms_enrollment_campaign_details: 'Campaign Details',
    sms_enrollment_campaign_message_settings: 'Campaign Message Settings',
    sms_enrollment_campaign_sample_messages: 'Campaign Sample Messages',
    sms_enrollment_preview_campaign_description: 'Campaign Description',
    sms_enrollment_preview_business_type: 'Business Type',
    sms_enrollment_use_cases: 'Use Cases',
    sms_enrollment_feature_disabled_tooltip: 'Feature has been disabled for Basic Plan accounts',
    sales_force_chat_settings: '{"communityURL":"https://phone.my.salesforce-sites.com/einsteinBot","sfURL":"https://phone.my.salesforce.com","orgId":"00D41000002iMoz","LACURL":"https://c.la2-c1-ph2.salesforceliveagent.com/content","LAURL":"https://d.la2-c1-ph2.salesforceliveagent.com/chat","depId":"5721K000000PgaS","butId":"5731K000000Pk8g","domain":"","botName":"Phone_Support_Bot","eswLiveAgentDevName":"EmbeddedServiceLiveAgent_Parent04I550000008OwyEAE_179154f586c","snapInURL":"","displayCTA":false}',
    branded_logo_url: '',
    advanced_settings_text: 'Advanced settings',
    branded_theme_override: '{}',
    communicator_nav_titles: JSON.stringify({
        'account-settings': 'Account settings',
        'business-hours': 'Business hours',
        calls: 'Calls',
        enrollment: 'SMS Enrollment',
        messages: 'Messages',
        voicemail: 'Voicemail',
        fax: 'Fax',
        controlPanel: 'Control Panel',
        legacyControlPanel: 'Classic Control Panel',
        moreServices: 'More Services',
        configure: 'Configure',
        people: 'Contacts',
        settings: 'Settings',
        personal_settings: 'My settings',
        webComSettings: 'Web.com Settings',
        meetings: 'Meetings',
        appsMarketPlace: 'Store',
        apps: 'Store',
        help: 'Help',
        support: 'Support',
        logout: 'Logout',
        numbers: 'Numbers',
        users: 'Users',
        phones: 'Phones',
        SMSRegistration: 'SMS registration',
        blockedNumbers: 'Blocked numbers',
        callLogs: 'Call logs',
        smsDeliveryStatus: 'SMS logs',
        statements: 'Statements',
        paymentMethods: 'Payment information',
        callTypes: 'Call types',
        geoDistribution: 'Calls geo distribution',
        'per-extension': 'Calls per extension',
        'per-number': 'Calls per number',
        total: 'Total calls',
        traffic: 'Calls by date',
        summary: 'Call summary',
        'company-information': 'Company information',
        'live-receptionist': 'Receptionist services'
    }),
    call_types_tab_enabled: true,
    apps_marketplace_nav_title: 'Store',
    apps_marketplace_title: 'Store',
    apps_marketplace_subtitle: 'Enhance your Phone.com experience with these products and services',
    apps_marketplace_help: 'Help us improve our integration support for your CRM!',
    apps_marketplace_connect: 'Connect Phone.com with the tools you love.',
    apps_marketplace_request: 'Request an app',
    apps_marketplace_learn_more: 'Learn more',
    apps_marketplace_installed_apps: 'Installed Apps',
    apps_marketplace_version_message: 'You are running the latest version',
    apps_marketplace_get_support: 'Get Support',
    apps_marketplace_app_tab_overview: 'Overview',
    apps_marketplace_app_tab_ratings: 'Ratings and Reviews',
    apps_marketplace_app_tab_version: 'Version history',
    apps_marketplace_app_general_info: 'General Information',
    apps_marketplace_app_note: 'Note',
    apps_marketplace_app_amplify_brand_title: 'Amplify your brand and delight your careers',
    apps_marketplace_app_container_test_id: 'appContainer',
    apps_marketplace_installed_app_container_test_id: 'installedAppContainer',
    apps_marketplace_addon_popup_title: 'New app plugin available!',
    apps_marketplace_addon_popup_visit_app_btn: 'Visit the app gallery',
    IAB_free_period_fee_label: 'By checking this box, you understand the first 3 months of service are free. A fee of $19.99 per month will then be billed to the card you have on file each month you use the service. You may cancel anytime.',
    IAB_promotion_period_in_months: 3,
    support_url: '',
    webphone_integration_upgrade_to_pro: 'You must upgrade your plan to Pro to access this feature.',
    webphone_integration_legacy_not_supportted: 'Sorry, this feature is not available to your account.',
    webphone_integration_mic_permission_text: 'Please allow access to your microphone to make calls.',
    web_phone_integrate_zoho: 'To integrate with zoho CRM, click the button below and follow the instructions to authorize your Phone.com account.',
    web_phone_load_error: 'There may be an issue setting up the app. Please try refreshing the page, or contacting support if the issue persists.',
    web_phone_sign_in_prompt: 'To sign in, click the button above. If the sign in window does not open, please disable your pop-up blocker.',
    account_settings_caller_id_toggle: 'Show a caller ID by default',
    caller_id_modal_default_company_caller_id_1: 'By default all phone numbers have a caller ID name corresponding to your company\'s caller ID name:',
    caller_id_modal_default_company_caller_id_2: 'If you wish to show a different caller ID name for this number, simply enter it below and save.',
    caller_id_modal_no_default_company_caller_id_1: 'With your current settings, phone numbers do not have a caller ID name by default.',
    caller_id_modal_no_default_company_caller_id_2: 'If you wish to show a different caller ID name for this number, simply enter it below and save.',
    redirect_to_business_hours: 'Use company business hours to handle calls differently at different times. To set up your schedule, go to',
    under_administration: 'under Administration.'
}

export default RemoteConfigDefaults
