import React from 'react'
import Typography from 'typography'
import { Button } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import Arrow from '../SVG/Arrow'
import { SignOutComponent } from '../SignOut/index'

const useStyles = makeStyles(styles)

const upgradePlanText = 'Upgrade to Pro'

/**
 *
 */
export function UpgradeToProComponent (props: {userType?: string}): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    let message = (<Typography className='upgrade-details' variant='body2' remoteConfigID='webphone_integration_upgrade_to_pro'/>)
    if (props.userType === 'legacy') {
        message = (<Typography className='upgrade-details' variant='body2' remoteConfigID='webphone_integration_legacy_not_supportted'/>)
    }
    /**
     *
     */
    const clickHandler = () => {
        const uri = 'https://my.phone.com/apps-marketplace'
        window.open(uri, '_blank')
    }

    return (
        <div className={classes.upgradeScreenRoot}>
            <div className={'logos'}>
                <img src={(theme as Record<string, any>).icons?.identityAreaLogo} alt="pdc logo" style={{ width: '56px' }} />
            </div>
            <div className={'upgrade-text'}>
                {message}
            </div>
            <div className={'upgrade-btn-root'} hidden={props.userType === 'legacy'}>
                <Button onClick={clickHandler} variant='contained' color='primary' size='large' endIcon={<Arrow />} className={'upgrade-integration-btn'}>
                    <Typography className='upgrade-btn-text'>{upgradePlanText}</Typography>
                </Button>
            </div>
            <div className={'sign-out-btn-root'}>
                <SignOutComponent />
            </div>
        </div>
    )
}
