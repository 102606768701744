import React from 'react'

/**
 *
 */
const PhoneRinging = (props: Record<string, any>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            fill="none"
            {...props}
        >
            <g fill="#fff">
                <path
                    fillRule="evenodd"
                    d="M3.743 6.53a11.018 11.018 0 0 0 4.623 4.956l1.655-1.543a.723.723 0 0 1 .747-.148c.805.297 1.68.473 2.58.505a.73.73 0 0 1 .702.752l-.089 2.537a.73.73 0 0 1-.752.7C6.384 14.053 1.046 8.328 1.285 1.503a.73.73 0 0 1 .752-.701L4.58.89a.73.73 0 0 1 .701.752 8.262 8.262 0 0 0 .324 2.61.73.73 0 0 1-.208.734L3.743 6.53Z"
                    clipRule="evenodd"
                />
                <path d="M14.385 7.034h-.455a.54.54 0 0 1-.524-.5 5.088 5.088 0 0 0-4.568-4.567.54.54 0 0 1-.499-.524V.99c0-.276.225-.502.5-.481a6.547 6.547 0 0 1 6.027 6.026c.02.276-.205.5-.481.5Z" />
                <path d="M11.476 7.034h-.455c-.276 0-.494-.227-.556-.496a2.18 2.18 0 0 0-1.63-1.63c-.269-.062-.496-.28-.496-.556v-.454c0-.276.225-.504.499-.466a3.64 3.64 0 0 1 3.104 3.104c.037.273-.19.498-.466.498Z" />
            </g>
        </svg>
    )
}

export default PhoneRinging
