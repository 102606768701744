/**
 *
 */
export const styles = (theme) => ({
    footer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px'
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        '& .callDescription': {
            margin: '10px auto',
            '& .h6-heading': {
                color: theme.palette.text.secondary,
                fontSize: '20px!important',
                fontWeight: '400!important'
            }
        },
        '& .callControls': {
            margin: '0px auto'
        }
    },
    incomingCallBody: {
        width: '70%',
        padding: '20% 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 'auto',
        '& .button': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0px 35px',
            '& .text': {
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '5px'
            }
        }
    },
    incomingCallFooter: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'flex-end',
        height: '100%',
        paddingRight: '25px',
        '& .ignoreButton': {
            width: '80px',
            border: `2px solid ${theme.palette.text.secondary}`,
            '& span': {
                fontSize: '10px!important',
                color: `${theme.palette.text.secondary}!important`
            }
        }
    },
    callInfo: {
        '& .call-info-root': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Montserrat!important',
            fontSize: '18px',
            fontWeight: 500,
            '& .name': {
                textTransform: 'uppercase',
                lineHeight: '18px'
            },
            '& .callerid': {
                lineHeight: '26px',
                letterSpacing: '-0.1px'
            }
        }
    },
    rejectCallButton: {
        height: 62,
        width: 62,
        '& svg': {
            fontSize: 30
        }
    },
    acceptCallButton: {
        width: 62,
        height: 62,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.action.disabledBg
        }
    },
    keypad: {
        '& .keypadBody': {
            padding: '20px'
        },
        '& .keypadHeader': {
            color: 'white',
            '& .keypadText': {
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                overflow: 'hidden',
                maxWidth: '200px'
            },
            '& .backButtonWrapper': {
                backgroundColor: 'transparent',
                '& .backButton': {
                    width: '24px',
                    height: '24px'
                }
            }
        }
    },
    icon: {
        fontSize: '32px!important'
    }
})
