import { IContactInfo } from './models'

interface DataStoreDelegates {
    getLocalContacts?: (filter: string) => Promise<IContactInfo[]>
}

/**
 *
 */
export class PdcDataStore {
    private mDelegates: DataStoreDelegates
    /**
     *
     */
    constructor (delegates: DataStoreDelegates) {
        this.mDelegates = delegates
    }

    /**
     *
     */
    public async getContacts (filter: string): Promise<IContactInfo[]> {
        if (this.mDelegates.getLocalContacts) {
            return this.mDelegates.getLocalContacts(filter)
        }
        return []
    }
}
