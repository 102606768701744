import { PdcClientApp } from 'my-pdc-client'

declare global {
    interface Window {
        dataLayer: any
    }
}

/**
 *
 */
export function gtmPush (event) {
    if (!window.dataLayer) {
        console.error('GTM is not loaded, event not pushed.')
        return
    }
    const user = PdcClientApp.user()
    const data = {
        // eslint-disable-next-line
        PDC_voip_id: user.voipId,
        // eslint-disable-next-line
        PDC_voip_phone_id: user.voipPhoneId,
        ...event
    }
    return window.dataLayer.push(data)
}
