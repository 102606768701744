import { Grid } from '@material-ui/core'
import React from 'react'
import { PhoneHeader } from '../../components'
import { viewStyles } from './styles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(viewStyles)

interface PhoneViewProps {
    title: string
    icon?: JSX.Element
    children: JSX.Element[]
}

/**
 *
 */
export function PhoneViewHeader (props: { children: JSX.Element }) {
    return props.children
}

/**
 *
 */
export function PhonwViewBody (props: { children: JSX.Element }) {
    return props.children
}

/**
 *
 */
export function PhoneViewFooter (props: { children: JSX.Element }) {
    return props.children
}

/**
 *
 */
export function PhoneView (props: PhoneViewProps) {
    const classes = useStyles(props)
    let header = (<></>)
    let body = (<></>)
    let footer = (<></>)
    props.children.forEach((child: JSX.Element) => {
        if (child.type === PhoneViewHeader) {
            header = child
        } else if (child.type === PhonwViewBody) {
            body = child
        } else if (child.type === PhoneViewFooter) {
            footer = child
        }
    })
    return (<Grid container direction='column' className={classes.viewRoot}>
        <Grid item className={'viewHeaderRoot'}>
            <PhoneHeader title={props.title} icon={props.icon}>
                {header}
            </PhoneHeader>
        </Grid>
        <Grid item className='viewBodyFooterRoot'>
            <Grid container direction='column' className={'viewContentRoot'}>
                <Grid item className={'viewBody'}>
                    {body}
                </Grid>
                <Grid item className={'viewFooter'}>
                    {footer}
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}
