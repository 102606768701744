/**
 *
 */
const styles = (theme) => ({
    signOutRoot: {
        backgroundColor: '#8C98A0',
        width: '100%',
        padding: '8px 0',
        '& .sign-out-btn': {
            margin: 'auto',
            '& .sign-out-btn-text': {
                color: '#fff!important',
                fontFamily: 'Montserrat!important',
                fontSize: '12px!important',
                fontStyle: 'normal!important',
                fontWeight: '500!important'
            }
        }
    }
})

export default styles
