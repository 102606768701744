import React from 'react'
import ReactDOM from 'react-dom'
import 'public-path'
import './index.css'
// import App from './App'
// import * as serviceWorker from './serviceWorker'
import { ScreenSizeProvider, LeaveProvider } from 'providers'
import 'core-js/es'
import 'es6-promise/auto'

import { theme } from 'get-theme'
import ErrorCatcher from 'error-catcher'
import { ThemeProvider } from '@material-ui/styles'
import { WebPhone } from 'web-phone'

const docRoot = document.getElementById('webPhoneRoot') ? document.getElementById('webPhoneRoot') : document.getElementById('root')
ReactDOM.render(
    <ErrorCatcher theme={theme}>
        <ThemeProvider theme={theme}>
            <ScreenSizeProvider sizes={theme.screenViewSizesConfigureApp}>
                <LeaveProvider>
                    <WebPhone/>
                </LeaveProvider>
            </ScreenSizeProvider>
        </ThemeProvider>
    </ErrorCatcher>,
    docRoot)

// set up un auth url redirect to sign in

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/*
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker && waitingServiceWorker.state !== 'redundant') {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          waitingServiceWorker.postMessage({ type: "WAITING" })
          console.log('reloading for new service worker')
          setTimeout(function(){ window.location.reload() }, 500)
        }
      })
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
    }
  }
})
*/
