/**
 *
 */
const styles = (theme) => ({
    webPhoneRoot: {
        position: 'fixed',
        zIndex: '1201',
        background: 'white',
        width: '300px',
        height: '500px',
        paddingBottom: '24px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            WebkitAppearance: 'none !important',
            width: '0px'
        }
    },
    virtualExtensionMessage: {
        width: '100%',
        height: '100%',
        padding: '40% 20%',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light
    }
})

/**
 *
 */
export { styles }
