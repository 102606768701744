/**
 *
 */
const styles = (theme) => ({
    deleteIntegrationScreenRoot: {
        display: 'flex',
        flexDirection: 'column',
        padding: '24% 12%',
        alignItems: 'center',
        rowGap: '1rem',
        '& .divider': {
            width: '45px'
        },
        '& .disconnect-zoho-info': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            padding: '0 4px',
            '& .disconnect-zoho-heading': {
                color: theme.palette.text.primary,
                textAlign: 'center',
                fontSize: '14px!important',
                fontFamily: 'Montserrat!important',
                fontWeight: '500!important',
                lineHeight: '22px!important'
            },
            '& .disconnect-zoho-info-text': {
                color: theme.palette.text.secondary,
                fontSize: '11px!important',
                fontFamily: 'Montserrat!important',
                fontWeight: '500!important',
                lineHeight: '16px!important'
            },
            '& .disconnect-zoho-text-root': {
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
                marginTop: '30px',
                '& .disconnect-zoho-text': {
                    color: theme.palette.text.primary,
                    fontSize: '12px!important',
                    fontFamily: 'Montserrat!important',
                    fontWeight: '500!important',
                    lineHeight: '19px!important',
                    textAlign: 'center'
                },
                '& .btn-root': {
                    display: 'flex',
                    justifyContent: 'space-around',
                    '& .disconnect-btn': {
                        width: '116px'
                    }
                }
            }
        }
    }
})

export default styles
