/**
 *
 */
const styles = (theme) => ({
    integrationScreenRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        '& .logos': {
            display: 'flex',
            columnGap: '16px',
            paddingTop: '50%',
            '& .divider': {
                width: '2px'
            }
        },
        '& .zoho-integration-text': {
            paddingTop: '10%',
            '& .zoho-details': {
                color: theme.palette.text.primary,
                fontFamily: 'Montserrat!important',
                fontSize: '12px!important',
                fontWeight: '600!important',
                lineHeight: '19px!important',
                textAlign: 'center'
            }
        },
        '& .zoho-integration-btn-root': {
            paddingTop: '20%',
            paddingBottom: '20%',
            '& .zoho-integration-btn': {
                '& .zoho-integration-btn-text': {
                    fontFamily: 'Montserrat!important',
                    fontSize: '13px!important',
                    fontWeight: '600!important',
                    lineHeight: '16px!important'
                }
            }
        },
        '& .sign-out-btn-root': {
            position: 'absolute',
            bottom: 0,
            backgroundColor: '#8C98A0',
            width: '100%',
            padding: '8px 0',
            '& .sign-out-btn': {
                margin: 'auto',
                '& .sign-out-btn-text': {
                    color: '#fff!important',
                    fontFamily: 'Montserrat!important',
                    fontSize: '12px!important',
                    fontStyle: 'normal!important',
                    fontWeight: '500!important'
                }
            }
        }
    }
})

export default styles
