import React from 'react'
import Typography from 'typography'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import styles from './styles'

const useStyles = makeStyles(styles)

/**
 *
 */
export const Logo = () => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <div className={classes.logoRoot}>
            <img src={(theme as Record<string, any>).icons?.identityAreaLogo} alt="pdc logo" style={{ width: '30px' }} />
            <Typography className={classes.phonecom} variant='h6'>
                Phone.com
            </Typography>
        </div>
    )
}
