import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import IconButton from 'icon-button-mui'
import Typography from 'typography'
import { Call, CallState, WebPhoneContext } from '../../WebPhoneContext'
import { AddIcon, CallTransferIcon, Dialpad9Icon, PauseIcon, MicOffIcon, MicIcon as MicOnIcon, VolumeOffIcon, VolumeUpIcon } from 'svg-icons/src'
import styles from './styles'
import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import UpdateIcon from '@material-ui/icons/Update'

const useStyles = makeStyles(styles)

function AudioInputControl (props: { disabled: boolean, muted: boolean, onClick: () => void }) {
    const classes = useStyles()
    const muted = props.muted
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${muted ? 'muted' : ''} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-mute-button'
    >
        <div className='control-button-wrapper'>
            {muted ? <MicOffIcon className='control-button-icon'/> : <MicOnIcon className='control-button-icon'/>}
            <Typography variant='overline'>MUTE</Typography>
        </div>
    </IconButton>)
}

function AudioOutputControl (props: { onClick: () => void, muted: boolean, disabled: boolean }) {
    const classes = useStyles()
    const muted = props.muted
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${muted ? 'muted' : ''} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-audio-button'
    >
        <div className='control-button-wrapper'>
            {muted ? <VolumeOffIcon className='control-button-icon'/> : <VolumeUpIcon className='control-button-icon'/>}
            <Typography variant='overline'>AUDIO</Typography>
        </div>
    </IconButton>)
}

function KeypadButton (props: { onClick: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-keypad-button'
    >
        <div className='control-button-wrapper'>
            <Dialpad9Icon className='control-button-icon'/>
            <Typography variant='overline'>KEYPAD</Typography>
        </div>
    </IconButton>)
}

// eslint-disable-next-line no-unused-vars
function AddCallButton (props: { onClick: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-add-call-button'
    >
        <div className='control-button-wrapper'>
            <AddIcon className='control-button-icon'/>
            <Typography variant='overline'>ADD</Typography>
        </div>
    </IconButton>)
}

// eslint-disable-next-line no-unused-vars
function TransferCallButton (props: { onClick: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-transfer-button'
    >
        <div className='control-button-wrapper'>
            <CallTransferIcon className='control-button-icon'/>
            <Typography variant='overline'>TRANSFER</Typography>
        </div>
    </IconButton>)
}

function HoldCallButton (props: { onClick: () => void, onHold: boolean, disabled: boolean }) {
    const classes = useStyles()
    const onHold = props.onHold
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${onHold ? 'onHold' : ''} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-hold-button'
    >
        <div className={`control-button-wrapper ${onHold ? 'onHold' : ''}`}>
            <PauseIcon className='control-button-icon'/>
            <Typography variant='overline'>{onHold ? 'UN' : ''}HOLD</Typography>
        </div>
    </IconButton>)
}

function AddNotesButton (props: { onClick: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-notes-button'
    >
        <div className='control-button-wrapper'>
            <CreateIcon className='control-button-icon'/>
            <Typography variant='overline'>NOTES</Typography>
        </div>
    </IconButton>)
}

function GoToCallLogButton (props: { onClick: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging no-padding`}
        onClick={props.onClick}
        data-test-id='softphone-keys-jump-to-call-button'
    >
        <div className='control-button-wrapper'>
            <UpdateIcon className='control-button-icon'/>
            <Typography variant='overline'>CALL LOGS</Typography>
        </div>
    </IconButton>)
}

interface CallControlsProps {
    toggleKeypad: () => void
    toggleNotes: () => void
    call: Call
    disabled: boolean
}
/**
 *
 */
export function CallControls (props: CallControlsProps) {
    const call = props.call
    const classes = useStyles()
    const webPhone = useContext(WebPhoneContext)
    const [onHold, setOnHold] = useState(call?.isOnHold() || false)
    const [microphoneEnabled, setMicrophoneEnabled] = useState(webPhone.audioRecordingEnabled)
    const [speakerEnabled, setSpeakerEnabled] = useState(webPhone.audioPlaybackEnabled)
    const [integrationCallId, setIntegrationCallId] = useState(call ? webPhone.integrationCalls[call.userString()] : null)
    /**
     *
     */
    const isCallConnected = () => (call?.state === CallState.Connected || call?.state === CallState.OnHold)
    const [callConnected, setCallConnected] = useState(isCallConnected())
    useEffect(() => {
        setOnHold(call?.isOnHold() || false)
        setCallConnected(isCallConnected())
        setIntegrationCallId((call && webPhone.integrationCalls[call.userString()]) ? webPhone.integrationCalls[call.userString()] : null)
        console.log('integration call: ', integrationCallId)
        const audio = call?.getAudioSettings()
        if (audio) {
            setMicrophoneEnabled(audio.recording.enabled)
            setSpeakerEnabled(audio.playback.enabled)
        }
    }, [call, webPhone, webPhone.integrationCalls])
    /**
     *
     */
    const toggleHold = () => call?.hold(!call?.isOnHold())
    /**
     *
     */
    const toggleMicrophone = () => {
        webPhone.enableAudioRecording(!microphoneEnabled)
        setMicrophoneEnabled(!microphoneEnabled)
    }
    /**
     *
     */
    const toggleSpeaker = () => {
        webPhone.enableAudioPlayback(!speakerEnabled)
        setSpeakerEnabled(!speakerEnabled)
    }
    /*
    * for zoho integration
    */
    const goToCallLog = () => {
        if (integrationCallId) {
            webPhone.actions.goToCallLog(integrationCallId)
        }
    }
    return (<Grid container direction='column' className={classes.callControlsWrapper}>
        <Grid container item direction='row' className={'row'}>
            <Grid item className={'item'}>
                <AudioInputControl onClick={toggleMicrophone} muted={!microphoneEnabled} disabled={false}/>
            </Grid>
            <Grid item className={'item'}>
                <KeypadButton onClick={props.toggleKeypad} disabled={call?.state !== CallState.Connected}/>
            </Grid>
            <Grid item className={'item'}>
                <AudioOutputControl onClick={toggleSpeaker} muted={!speakerEnabled} disabled={false}/>
            </Grid>
        </Grid>
        <Grid container item direction='row' className={'row'}>
            <Grid item className={'item'}>
                <HoldCallButton onClick={toggleHold} onHold={onHold} disabled={!callConnected}/>
            </Grid>
            <Grid item className={'item'}>
                <AddNotesButton onClick={props.toggleNotes} disabled={integrationCallId === null || webPhone.actions.addCallNotes === null}/>
            </Grid>
            <Grid item className={'item'}>
                <GoToCallLogButton onClick={goToCallLog} disabled={integrationCallId === null || webPhone.actions.goToCallLog === null}/>
            </Grid>
        </Grid>
    </Grid>)
}
