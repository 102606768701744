import React, { useEffect } from 'react'
import Typography from 'typography'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import { SignOutComponent } from '../SignOut/index'

const useStyles = makeStyles(styles)

/**
 *
 */
export function MicrophonePermissionComponent (props: { logout: () => void }): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    const [hasPermission, setHasPermission] = React.useState(true)
    useEffect(() => {
        navigator.permissions?.query({ name: 'microphone' }).then((permissionStatus) => {
            if (permissionStatus.state === 'granted') {
                setHasPermission(true)
            } else {
                setHasPermission(false)
            }
            permissionStatus.onchange = () => {
                if (permissionStatus.state === 'granted') {
                    setHasPermission(true)
                } else {
                    setHasPermission(false)
                }
            }
        }).catch(() => {
            navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
                setHasPermission(true)
            }).catch(() => {
                setHasPermission(false)
            })
        })
    })
    return (
        <div className={classes.microphoneScreenRoot} hidden={hasPermission}>
            <div className={'logos'}>
                <img src={(theme as Record<string, any>).icons?.identityAreaLogo} alt="pdc logo" style={{ width: '56px' }} />
            </div>
            <div className={'mic-permission-text'}>
                <Typography className='details' variant='body2' remoteConfigID='webphone_integration_mic_permission_text'/>
            </div>
            <div className={'sign-out-btn-root'}>
                <SignOutComponent logout={props.logout} />
            </div>
        </div>
    )
}
