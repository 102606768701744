import { Grid, Typography, makeStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { Call, CallDirection, CallState, CallUser, WebPhoneContext } from '../../WebPhoneContext'
import { PhoneView, PhoneViewHeader, PhonwViewBody, PhoneViewFooter } from '../PhoneView'
import Timer from 'timer'
import { styles } from './styles'
import { CallControls, Dialpad, AddNotesPanel } from '../../components'
import { ArrowBackIosIcon } from 'svg-icons/src'
import IconButton from 'icon-button-mui'
import CallIcon from '@material-ui/icons/Call'
import CallEndIcon from '@material-ui/icons/CallEnd'
import CloseIcon from '@material-ui/icons/Close'
import Button from 'button-mui'
import PhoneRinging from '../../components/SVG/PhoneRinging'

const useStyles = makeStyles(styles)

function SingleCallInfo (props: { user: CallUser, showNameAndCallerId?: boolean }): JSX.Element {
    const user = props.user
    const [isContact, setIsContanct] = useState(user?.contactId !== '')
    const contactInfo = () => {
        return (<>{user?.displayName}</>)
    }

    useEffect(() => {
        setIsContanct(user?.contactId !== '')
    }, [user, user?.contactId])

    const contactName = <div className='name'>{contactInfo()}</div>
    const callerId = <div className='callerid'>{user?.callerId}</div>

    return (<div className='call-info-root'>
        {
            props.showNameAndCallerId
                ? <>{isContact && contactName}{callerId}</>
                : (isContact ? contactName : callerId)
        }
    </div>)
}

function ConferenceCallInfo (props: { users: CallUser[] }): JSX.Element {
    const users = props.users
    const userListItems = users.map((user) => {
        return (<li key={user.contactId}>
            <Grid container>
                <Grid item xs={12}>
                    {user.callerId}
                </Grid>
            </Grid>
        </li>)
    })
    return (<div>
        <ul>{userListItems}</ul>
    </div>)
}

function CallUserInfo (props: { call: Call, showNameAndCallerId?: boolean }) {
    const call = props.call
    const users = call.users
    const classes = useStyles()
    const [isConferenceCall, setIsConferenceCall] = useState(users.length > 1)
    useEffect(() => {
        setIsConferenceCall(call.users.length > 1)
    }, [props.call.users])
    return (<div className={classes.callInfo}>
        {isConferenceCall
            ? <ConferenceCallInfo users={users}/>
            : <SingleCallInfo user={users[0]} showNameAndCallerId={props.showNameAndCallerId}/>}
    </div>)
}

function KeypadView (props: { call: Call, onBack: () => void }): JSX.Element {
    const [textValue, setTextValue] = useState('')
    const classes = useStyles()
    const onKeyPress = (key: string) => {
        let text = textValue
        if (text.length > 16) {
            text = text.slice(1, 17)
        }
        setTextValue(text + key)
        props.call.sendDTMF(key)
    }
    const header = () => {
        return (<Grid container direction={'row'} className={'keypadHeader'}>
            <Grid item xs={2}>
                <IconButton
                    onClick={props.onBack}
                    className={'backButtonWrapper disable-dragging'}
                    data-test-id='softphone-dialpad-go-back-icon'
                >
                    <ArrowBackIosIcon className={'backButton'}/>
                </IconButton>
            </Grid>
            <Grid item xs={8} className={'keypadText'}>
                <Typography color={'inherit'} variant={'h5'}>{textValue}</Typography>
            </Grid>
            <Grid item xs={2}>
            </Grid>
        </Grid>)
    }
    const body = () => {
        return (<div className={'keypadBody'}>
            <Dialpad enableDTMFTones={true} onClick={onKeyPress}/>
        </div>)
    }
    return (<div className={classes.keypad}>
        <PhoneView
            title={'In Call'}
        >
            <PhoneViewHeader>
                {header()}
            </PhoneViewHeader>
            <PhonwViewBody>
                {body()}
            </PhonwViewBody>
            <PhoneViewFooter>
                {<></>}
            </PhoneViewFooter>
        </PhoneView>
    </div>)
}

function EndCallButton (props: { onClick: () => void, disabled: boolean, incomingCallView?: boolean }) {
    const classes = useStyles()
    return (<IconButton
        color={'urgent'}
        disabled={props.disabled}
        className={`${classes.rejectCallButton} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-end-call-button'
    >
        {
            props.incomingCallView
                ? <CloseIcon className={`control-button-icon ${classes.icon}`}/>
                : <CallEndIcon className={`control-button-icon ${classes.icon}`}/>
        }
    </IconButton>)
}

function AnswerCallButton (props: { onClick: () => void, disabled: boolean }) {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.acceptCallButton} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-end-call-button'
    >
        <CallIcon className={`control-button-icon ${classes.icon}`}/>
    </IconButton>)
}
/**
 *
 */
function InCallView (props: { call: Call }): JSX.Element {
    const classes = useStyles()
    const webPhone = useContext(WebPhoneContext)
    const [call, setCall] = React.useState(props.call)
    const [showKeypad, setShowKeypad] = React.useState(false)
    const [showNotes, setShowNotes] = React.useState(false)
    const [recordId, setRecordId] = React.useState(webPhone.integrationCalls[call.userString()] || null)
    const toggleKeypad = () => {
        setShowKeypad(!showKeypad)
    }
    const toggleNotes = () => {
        setShowNotes(!showNotes)
    }
    useEffect(() => {
        setCall(props.call)
        setRecordId(webPhone.integrationCalls[call.userString()] || null)
    }, [props.call, webPhone])

    const callState = call.state === CallState.Connecting ? 'Connecting' : 'Connected'

    const body = () => {
        const message = call.state === CallState.Connecting
            ? 'Ringing...'
            : <Timer startTime={call.startTime / 1000}/>
        return (<Grid container direction='column' className={classes.body}>
            <Grid item className={'callDescription'}>
                <Typography variant='h6' className={'h6-heading'}>{message}</Typography>
            </Grid>
            <Grid item className={'callControls'} style={{ width: showNotes ? '85%' : 'auto', marginTop: showNotes ? '15px' : 'auto' }}>
                <AddNotesPanel onCloseNotes={toggleNotes} recordId={recordId} hidden={!showNotes}/>
                {showNotes
                    ? <></>
                    : <CallControls toggleKeypad={toggleKeypad} toggleNotes={toggleNotes} call={call} disabled={true}/>
                }
            </Grid>
        </Grid>)
    }
    const footer = () => {
        const onClick = () => {
            call.terminate()
        }
        if (showNotes) return (<></>)
        return (<div className={classes.footer}>
            <EndCallButton onClick={onClick} disabled={false}/>
        </div>)
    }
    if (showKeypad) {
        return (<KeypadView call={call} onBack={toggleKeypad}/>)
    }

    return (<PhoneView
        title={callState}
        icon={<PhoneRinging style={{ marginRight: '5px' }} />}
    >
        <PhoneViewHeader>
            <CallUserInfo call={call}/>
        </PhoneViewHeader>
        <PhonwViewBody>
            {body()}
        </PhonwViewBody>
        <PhoneViewFooter>
            {footer()}
        </PhoneViewFooter>
    </PhoneView>)
}

function IncomingCallView (props: { call: Call}): JSX.Element {
    const classes = useStyles()
    const phoneContext = useContext(WebPhoneContext)
    const [call, setCall] = useState(props.call)
    const rejectCall = () => call.reject()
    const acceptCall = () => call.accept()
    const ignoreCall = () => call.ignore()
    useEffect(() => {
        setCall(props.call)
    }, [props.call])
    useEffect(() => {
        phoneContext.maximizeWebPhone()
    }, [])
    const body = () => {
        return (<div className={classes.incomingCallBody}>
            <div className={'button'}>
                <AnswerCallButton onClick={acceptCall} disabled={false}/>
                <Typography className={'text'} variant='overline'>ANSWER</Typography>
            </div>
            <div className={'button'}>
                <EndCallButton onClick={rejectCall} disabled={false} incomingCallView={true} />
                <Typography className={'text'} variant='overline'>DECLINE</Typography>
            </div>
        </div>)
    }
    const footer = () => {
        return (<div className={classes.incomingCallFooter}>
            <Button onClick={ignoreCall} className={'ignoreButton'} variant="outlined" color='secondary'>IGNORE</Button>
        </div>)
    }

    return (<PhoneView
        title={'Incoming Call'}
        icon={<PhoneRinging style={{ marginRight: '5px' }} />}
    >
        <PhoneViewHeader>
            <CallUserInfo call={call} showNameAndCallerId={true} />
        </PhoneViewHeader>
        <PhonwViewBody>
            {body()}
        </PhonwViewBody>
        <PhoneViewFooter>
            {footer()}
        </PhoneViewFooter>
    </PhoneView>)
}

/**
 *
 */
export function OngoingCallsView (props: { calls: Call[] }): JSX.Element {
    const calls = props.calls
    const [viewCall, setViewCall] = useState<Call>(calls[0])
    if (calls.length === 0) return (<></>)
    useEffect(() => {
        // incoming call first
        const incomingCalls = calls.filter((call) => call.state === CallState.Connecting && call.direction === CallDirection.Incoming)
        if (incomingCalls.length > 0) {
            setViewCall(incomingCalls[0])
            return
        }
        // then outgoing
        const outgoingCalls = calls.filter((call) => call.state === CallState.Connecting && call.direction === CallDirection.Outgoing)
        if (outgoingCalls.length > 0) {
            setViewCall(outgoingCalls[0])
            return
        }
        // then the connected call (should only ever be 1)
        const connectedCalls = calls.filter((call) => call.state === CallState.Connected)
        if (connectedCalls.length > 0) {
            setViewCall(connectedCalls[0])
            return
        }
        // lastly calls on hold
        const onHoldCalls = calls.filter((call) => call.state === CallState.OnHold)
        if (onHoldCalls.length > 0) {
            setViewCall(onHoldCalls[0])
        }
    }, [calls.length])
    if (viewCall.state === CallState.Connecting && viewCall.direction === CallDirection.Incoming) {
        return (<IncomingCallView call={viewCall}/>)
    }
    return (<InCallView call={viewCall}/>)
}
