import React, { useState, useContext, useEffect, useRef } from 'react'
import Typography from 'typography'
import { formatPhoneNumber } from 'phone-numbers'
import { ArrowDropdownIcon } from 'svg-icons/src'
import { CheckIcon } from 'svg-icons'
import { useToggle } from 'hooks'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import { WebPhoneContext } from '../../WebPhoneContext'
import { useOnClickAway } from 'hooks'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

type Location = { county: string | null, state: string | null, city: string | null }

/***/
/**
 *
 */
export interface OutboundNumberInfo { label: string, number: string, assigned: number, location: Location }

/***/
/**
 *
 */
const OutboundNumberSelector = (): JSX.Element => {
    const webPhone: any = useContext(WebPhoneContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const [fromNumbers, setOutboundNumbers] = useState(webPhone.phoneNumbers)
    const [selected, setSelected] = useState(webPhone.outboundCallerId)
    const [expanded, toggleExpanded] = useToggle(false)
    const clickableAreaRef = useRef<HTMLDivElement>(null)
    const menuWrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setOutboundNumbers(webPhone.phoneNumbers)
        setSelected(webPhone.outboundCallerId)
    }, [webPhone.phoneNumbers, webPhone.outboundCallerId])

    const selectNumber = async (index: number) => {
        const newSelected = fromNumbers[index]
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'from-number-selector-select', value: index })
        toggleExpanded(false)
        setSelected(newSelected)
        await webPhone.setOutboundCallerId(newSelected.number)
    }

    useOnClickAway(
        clickableAreaRef, // 1. When you click out of this element
        () => {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'from-number-selector-click-away', value: true })
            toggleExpanded(false)
        }, // 4. Then call this function
        document.getElementById('softphone') as HTMLDivElement, // 2. But inside of this element
        [menuWrapperRef] // 3. These elements are excluded
    )
    const DisplayNumber = (number: OutboundNumberInfo): string => {
        const phoneNumber = formatPhoneNumber(number.number)
        if (['', number.number, phoneNumber].includes(number.label)) return phoneNumber
        return phoneNumber
    }
    const MenuItem = (number: OutboundNumberInfo): JSX.Element => {
        const display = DisplayNumber(number)
        return (<Typography variant='body2'>{display}</Typography>)
    }
    const classes = useStyles()
    const classNames = `${classes.fromNumberSection} ${expanded ? 'expanded' : ''} disable-dragging`
    const displayName = selected?.label || formatPhoneNumber(selected?.number)
    return (
        <div className={classNames}>
            <div className='head-button'>
                <div
                    className = {`clickable-area ${!webPhone.ready ? 'disabled' : ''}`}
                    onClick = {() => toggleExpanded()}
                    ref = {clickableAreaRef}
                >
                    {displayName
                        ? <>
                            <Typography variant='caption' className={'dropdownCaption'}>Calling from: {displayName}</Typography>
                            <ArrowDropdownIcon/>
                        </>
                        : (<Typography variant='caption' className={'dropdownCaption'}>Gathering Phone Numbers</Typography>)
                    }
                </div>
            </div>
            {expanded
                ? <div className='menu-wrapper' ref={menuWrapperRef}>
                    <div className = 'menu-item' key='selected'>
                        <div className='number-info'>
                            <Typography variant='body1'>{selected.label}</Typography>
                            {(selected.label !== formatPhoneNumber(selected.number)) && MenuItem(selected)}
                            {selected.location?.city
                                ? <Typography variant='body2'>{selected.location.city}, {selected.location.state}</Typography>
                                : null
                            }
                        </div>
                        <CheckIcon/>
                    </div>
                    {fromNumbers.map((fromNumber, i) => {
                        const isLabelSameAsNumber = fromNumber.label === formatPhoneNumber(fromNumber.number)
                        return (
                            <div
                                className='menu-item'
                                onClick = {() => selectNumber(i)}
                                hidden={fromNumber.number === selected.number}
                                key = {i}
                            >
                                <div className='number-info'>
                                    <Typography variant='body1'>{fromNumber.label}</Typography>
                                    {!isLabelSameAsNumber && MenuItem(fromNumber)}
                                    {fromNumber.location?.city
                                        ? <Typography variant='body2'>{fromNumber.location.city}, {fromNumber.location.state}</Typography>
                                        : null
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
                : null
            }
        </div>
    )
}

/**
 *
 */
export { OutboundNumberSelector }
