/**
 *
 */
const styles = (theme) => ({
    loadingScreenRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        position: 'absolute',
        '& .logo-root': {
            position: 'absolute',
            bottom: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        '& .spinner': {
            margin: 'auto'
        },
        '& .timeout-message': {
            margin: '16px',
            paddingTop: '30%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        '& .sign-out-btn-root': {
            position: 'absolute',
            bottom: 0,
            backgroundColor: '#8C98A0',
            width: '100%',
            padding: '8px 0',
            '& .sign-out-btn': {
                margin: 'auto',
                '& .sign-out-btn-text': {
                    color: '#fff!important',
                    fontFamily: 'Montserrat!important',
                    fontSize: '12px!important',
                    fontStyle: 'normal!important',
                    fontWeight: '500!important'
                }
            }
        }
    }
})

export default styles
