import React from 'react'
import { PhoneToolbar } from './PhoneToolbar'
import { headerStyles } from './styles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(headerStyles)

interface PhoneHeaderProps {
    title: string
    icon?: JSX.Element
    children: JSX.Element
}

/**
 *
 */
export function PhoneHeader (props: PhoneHeaderProps): JSX.Element {
    const classes = useStyles()
    return (<div className={classes.phoneHeaderRoot}>
        <PhoneToolbar title={props.title} icon={props.icon} />
        <div className={'headerContent'}>{props.children}</div>
    </div>)
}
