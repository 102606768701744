import React, { Component, createContext } from 'react'
import { PdcClientApp, PdcAppConfig, PdcClientState, IContactInfo, IUserInfo } from 'my-pdc-client'
import { Call, CallUser, CallClientState, CallDirection, CallState, PdcCallClient, PhoneNumber } from 'my-pdc-calling'
import { deleteIntegration, initIntegration, IntegrationDetails, PdcIntegrationConfig, IntegrationCallEvent } from 'my-pdc-integration'
import { LoadingComponent } from './components/LoadingScreen'
import { PDCOpenConnection } from 'web-socket'
import { getRecordingDevices, getPlaybackDevices } from 'audio-devices'
import { MicrophonePermissionComponent } from './components/MicrophonePermission'
import PhoneComLoginCenterClient from 'pdc-login-center-widget-lib'
import { SignInComponent } from './components/SignInComponent'
import { PdcSession } from 'my-pdc-client/src/PdcSession'

interface WebPhoneState {
    connected: boolean
    ready: boolean
    loading: boolean
    accessToken: string
    calls: Call[]
    appIntialized: boolean
    appConnected: boolean
    outboundCallerId: PhoneNumber
    phoneNumbers: PhoneNumber[]
    audioPlaybackEnabled: boolean
    audioRecordingEnabled: boolean
    integrationDetails: null | IntegrationDetails
    inboundRingAudioEnabled: boolean
    outboundRingAudioEnabled: boolean
    callWaitingRingAudioEnabled: boolean
    ringtoneVolume: number
    integrationCalls: { [callId: string]: string }
    userInfo: IUserInfo
}
interface WebPhoneProps {
    children: JSX.Element[]
}
interface WebPhoneIntegrationActions {
    goToCallLog?: (callId: string) => void
    addCallNotes?: (callId: string, notes: string) => Promise<void>
    deleteIntegration?: () => void
}
interface IWebPhoneContext extends WebPhoneState {
    calls: Call[]
    outboundCallerId: PhoneNumber
    phoneNumbers: PhoneNumber[]
    integrationDetails: null | IntegrationDetails
    ringtoneVolume: number
    integrationCalls: { [callId: string]: string}
    actions: WebPhoneIntegrationActions
    setOutboundCallerId: (number: string) => void
    startCall: (number: string) => void
    enableAudioPlayback: (enable: boolean) => void
    enableAudioRecording: (enable: boolean) => void
    maximizeWebPhone: () => void
    minimizeWebPhone: () => void
    getRecordingDevice: () => MediaDeviceInfo
    getPlaybackDevice: () => MediaDeviceInfo
    setRecordingDevice: (device: MediaDeviceInfo) => Promise<void>
    setPlaybackDevice: (device: MediaDeviceInfo) => Promise<void>
    setRingVolume: (volume: number) => void
    logout: () => void
    enableInboundRingAudio: (enable: boolean) => void
    enableCallWaitingRingAudio: (enable: boolean) => void
    enableOutboundRingAudio: (enable: boolean) => void
}
const WebPhoneContext = createContext<IWebPhoneContext>({} as IWebPhoneContext)

declare global {
    interface Window {
        maximizeWebPhone: () => void,
        minimizeWebPhone: () => void,
        getLocalContacts: (filter: string) => Promise<IContactInfo[]>,
        addCallNotes: (callId: string, noteContent: string) => Promise<void>
        goToCallLog: (callId: string) => void
        pdcIntegrationID?: string
        actions: WebPhoneIntegrationActions
    }
}
/**
 *
 */
class WebPhoneContextComponent extends Component<WebPhoneProps, WebPhoneState> {
    private mPdcClient: PdcClientApp
    private mPdcCallClient: PdcCallClient
    /**
     *
     */
    constructor (props) {
        super(props)
        this.state = {
            connected: false,
            ready: false,
            loading: false,
            accessToken: PdcSession.getSessionToken() || '',
            calls: [],
            outboundCallerId: { number: '', label: '', location: { country: '', state: '', city: '' } },
            appIntialized: false,
            appConnected: false,
            audioPlaybackEnabled: false,
            audioRecordingEnabled: false,
            phoneNumbers: [],
            integrationDetails: null,
            inboundRingAudioEnabled: true,
            outboundRingAudioEnabled: true,
            callWaitingRingAudioEnabled: true,
            ringtoneVolume: 1.0,
            integrationCalls: {},
            userInfo: {
                voipId: '',
                voipPhoneId: '',
                sessionId: '',
                sipAuthenticaiton: null,
                extensions: [],
                userId: '0',
                userType: 'basic',
                role: '',
                legacyUser: false
            }
        }
        this.initialize()
    }

    private initialize = () => {
        this.setState({ loading: true })
        try {
            let token = this.state.accessToken
            if (!token) {
                token = this.getLoginToken()
                if (token) this.setState({ accessToken: token })
                else return
            }
            // if app loads in iFrame, save the url to local storage
            if (window.location.href.includes('serviceOrigin')) {
                localStorage.setItem('serviceOrigin', window.location.href)
            } else {
                const serviceOrigin = localStorage.getItem('serviceOrigin')
                if (serviceOrigin) {
                    window.history.pushState({}, 'Web Phone', serviceOrigin)
                }
            }
            const appConfig: PdcAppConfig = {
                sessionToken: token,
                redirect: this.reloadApp,
                listener: this.appListener,
                localStore: {
                    getLocalContacts: (filter: string) => {
                        if (window.WebPhoneHandler.getLocalContacts) return window.WebPhoneHandler.getLocalContacts(filter)
                        return Promise.resolve([])
                    }
                }
            }
            PdcClientApp.initialize(appConfig).then(async (client) => {
                try {
                    this.mPdcClient = client
                    const callingConfig = {
                        listener: this.callClientListener,
                        playbackDevice: await getPlaybackDevices().then(devices => devices[0]),
                        recordingDevice: await getRecordingDevices().then(devices => devices[0])
                    }
                    this.mPdcCallClient = new PdcCallClient(client, callingConfig)
                    if (!this.isCallingAllowed()) {
                        this.pauseCallClient()
                    }
                } catch (error) {
                    console.error(error)
                }
                // start integration
                // testing zoho_crm
                // REMOVE THIS FLAG IN PRODUCTION
                // window.pdcIntegrationID = 'zoho_crm'
                console.log('integration: !!!!!!!!!!!!!!!!!', window.pdcIntegrationID)
                if (window.pdcIntegrationID && !this.state.integrationDetails) {
                    this.fetchIntegrationDetails()
                    const wsconn = new PDCOpenConnection(PdcClientApp.user())
                    wsconn.on(window.pdcIntegrationID, this.handleIntegrationEvent)
                }
                // end integration
            })
        } catch (error) {
            console.error(error)
        }
        this.setState({ loading: false })
    }

    private fetchIntegrationDetails = () => {
        const integrationConfig: PdcIntegrationConfig = {
            client: this.mPdcClient,
            id: window.pdcIntegrationID
        }
        this.setState({ loading: true })
        initIntegration(integrationConfig).then(res => {
            this.setState({ integrationDetails: res, loading: false })
            if (res.integrated) {
                this.resumeCallClient()
            } else {
                this.pauseCallClient()
            }
        })
    }

    private handleIntegrationEvent = (voipPhoneId: string, event: IntegrationCallEvent) => {
        console.log('event: ', voipPhoneId, event)
        if (event.event === 'integrate' || event.event === 'delete_integration') {
            this.fetchIntegrationDetails()
        } else if (event.event === 'new_call') {
            const calls = this.state.integrationCalls
            const callId = event.data.phone_number
            const integrationRecordId = event.data.integration_record_id
            calls[callId] = integrationRecordId
            this.setState({ integrationCalls: calls })
            console.log('integration call: ', this.state.integrationCalls)
        } else if (event.event === 'end_call') {
            const calls = this.state.integrationCalls
            const callId = event.data.phone_number
            delete calls[callId]
            this.setState({ integrationCalls: calls })
        }
    }

    private callClientListener = (stateUpdate: CallClientState) => {
        console.log('call client listener: ', stateUpdate)
        this.setState({
            connected: stateUpdate.connected,
            ready: stateUpdate.ready,
            calls: stateUpdate.calls,
            outboundCallerId: stateUpdate.callerId,
            audioPlaybackEnabled: stateUpdate.audioPlaybackEnabled,
            audioRecordingEnabled: stateUpdate.audioRecordingEnabled,
            phoneNumbers: stateUpdate.phoneNumbers,
            inboundRingAudioEnabled: stateUpdate.inboundRingAudioEnabled,
            outboundRingAudioEnabled: stateUpdate.outboundRingAudioEnabled,
            callWaitingRingAudioEnabled: stateUpdate.callWaitingRingAudioEnabled,
            ringtoneVolume: stateUpdate.ringtoneAudioVolume
        })
    }

    private appListener = (stateUpdate: PdcClientState) => {
        this.setState({
            appIntialized: stateUpdate.initialized,
            appConnected: stateUpdate.connected,
            userInfo: stateUpdate.userInfo
        })
    }

    /**
     *
     */
    private redirectLogin = () => {
        const location = window.location
        const authURL = process.env.REACT_APP_AC_URL
        const clientId = process.env.REACT_APP_AC_CLIENT_ID
        const redirectURL = `${authURL}/?client_id=${clientId}&redirect_uri=`
        const host = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
        window.location.href = `${redirectURL}${host}`
    }

    private redirectLogout = () => {
        if (window.pdcIntegrationID) {
            if (window.WebPhoneHandler.maximizeWebPhone) {
                window.WebPhoneHandler.maximizeWebPhone()
                this.redirectLogin()
            } else {
                setTimeout(() => {
                    if (window.WebPhoneHandler.maximizeWebPhone) {
                        window.WebPhoneHandler.maximizeWebPhone()
                    }
                    this.redirectLogin()
                }, 2500)
            }
        } else {
            this.redirectLogin()
        }
    }

    private reloadApp = () => {
        if (this.state.accessToken) {
            window.location.href = window.location.origin
            window.location.reload()
        }
    }

    private loginCallback = (data) => {
        console.log('login callback: ', data)
        this.setState({ loading: false, accessToken: data.access_token })
        this.initialize()
    }

    private openLoginWindow = () => {
        const clientId = process.env.REACT_APP_AC_CLIENT_ID
        const stage = process.env.REACT_APP_STAGE
        new PhoneComLoginCenterClient(clientId, { callbackSuccess: this.loginCallback })
            .setOptions({
                windowed: true,
                stage: stage,
                opedId: false
            })
            .open()
    }

    /**
     *
     */
    private getLoginToken (): string {
        let token = ''
        // Check if a CP Session ID is passed in the URL - check here first
        // before checking cookies to support multitab logins for CSRs
        if (window.location.href.indexOf('_sid') !== -1) {
            const queryString = window.location.search.substring(1)
            const parseQuery = (queryString: string, delimiterChar: string): { [key: string]: string } => {
                const query: { [key: string]: string } = {}
                const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split(delimiterChar)
                for (let i = 0; i < pairs.length; i++) {
                    const pair = pairs[i].split('=')
                    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
                }
                return query
            }
            const parsedQueryString = parseQuery(queryString, ';') // CP uses ';' to separate query string params
            let cpSessionFromUrl = parsedQueryString._sid
            if (cpSessionFromUrl) {
                cpSessionFromUrl = cpSessionFromUrl.replace('#', '') // CP sometimes appends # to URL
                token = cpSessionFromUrl
            }
        } else if (window.location.href.indexOf('access_token') !== -1) {
            const queryParamsSplit = window.location.hash.substring(1).split('&')
            queryParamsSplit.forEach(queryParam => {
                const qps = queryParam.split('=')
                if (qps[0] === 'access_token') {
                    token = qps[1]
                }
            })

            window.location.href.substr(0, window.location.href.indexOf('#'))
        }
        return token
    }

    private addCallNotes = (recordId: string, notes: string): Promise<void> => {
        if (window.addCallNotes) {
            return window.addCallNotes(recordId, notes)
        }
        return Promise.resolve()
    }

    private goToCallLog = (recordId: string) => {
        if (window.goToCallLog) {
            window.goToCallLog(recordId)
        }
    }

    private getIntegrationActions = (): WebPhoneIntegrationActions => {
        return {
            addCallNotes: window.addCallNotes ? this.addCallNotes : null,
            goToCallLog: window.goToCallLog ? this.goToCallLog : null,
            deleteIntegration: window.pdcIntegrationID
                ? () => {
                    this.setState({ loading: true })
                    this.pauseCallClient()
                    deleteIntegration(window.pdcIntegrationID).then(() => {
                        const details = this.state.integrationDetails
                        details.integrated = false
                        this.setState({ integrationDetails: details, loading: false })
                    })
                }
                : null
        }
    }

    private startCall = async (number: string): Promise<void | Call> => {
        console.log('call: ' + number)
        if (!(this.state.appIntialized && this.state.connected)) return
        if (this.state.integrationDetails?.integrated === false) return
        if (!this.isCallingAllowed()) return
        return this.mPdcCallClient.startCall(number).catch(error => {
            console.error('start call error: ', error)
        })
    }

    /**
     *
     */
    private isCallingAllowed () {
        return this.state.userInfo.userType === 'pro' || !window.pdcIntegrationID
    }

    private pauseCallClient = async () => {
        return setTimeout(() => this.mPdcCallClient.stopClient(), 5000)
    }

    private resumeCallClient = async () => {
        return this.mPdcCallClient.startClient()
    }

    /**
     * null funcs for STAGE
     */
    render () {
        console.log('web phone context: ', this.state)
        if (this.state.accessToken === '') {
            return (<SignInComponent openLoginWindow={this.openLoginWindow}/>)
        }
        if (!(this.state.appIntialized && this.state.connected && !this.state.loading)) {
            return (<>
                <MicrophonePermissionComponent logout={this.redirectLogout}/>
                <LoadingComponent/>
            </>)
        }
        const context = {
            ...this.state,
            actions: this.getIntegrationActions(),
            setOutboundCallerId: this.mPdcCallClient.setCallerId,
            startCall: this.startCall,
            enableAudioPlayback: this.mPdcCallClient.enableAudioPlayback,
            enableAudioRecording: this.mPdcCallClient.enableAudioRecording,
            enableInboundRingAudio: this.mPdcCallClient.setInboundRingAudioEnabled,
            enableCallWaitingRingAudio: this.mPdcCallClient.setCallWaitingRingAudioEnabled,
            enableOutboundRingAudio: this.mPdcCallClient.setOutboundRingAudioEnabled,
            setRingVolume: this.mPdcCallClient.setRingVolume,
            maximizeWebPhone: window.WebPhoneHandler.maximizeWebPhone || (() => null),
            minimizeWebPhone: window.WebPhoneHandler.minimizeWebPhone || (() => null),
            getRecordingDevice: this.mPdcCallClient.getRecordingDevice || (() => null),
            getPlaybackDevice: this.mPdcCallClient.getPlaybackDevice || (() => null),
            setRecordingDevice: this.mPdcCallClient.setRecordingDevice,
            setPlaybackDevice: this.mPdcCallClient.setPlaybackDevice,
            logout: this.mPdcClient.logout
        }
        return (
            <WebPhoneContext.Provider value={context}>
                <WebPhoneContext.Consumer>
                    {value => {
                        return (<>
                            <>{this.props.children}</>
                        </>)
                    }}
                </WebPhoneContext.Consumer>
            </WebPhoneContext.Provider>
        )
    }
}

/**
 *
 */
export { WebPhoneContext, WebPhoneContextComponent, Call, CallDirection, CallState, CallUser }
