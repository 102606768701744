/* eslint-disable @typescript-eslint/naming-convention */
import { isValidPhoneNumber, convertNumberToE164, formatToDigits } from 'phone-numbers'
import { UserAgent, URI } from 'sip.js'
/**
 *
 */
export class Callable {
    private _number: string
    private _isPhoneNumber: boolean
    private _isValid: boolean

    /**
     *
     */
    public get number (): string {
        return this._number
    }

    /**
     *
     */
    public get isPhoneNumber (): boolean {
        return this._isPhoneNumber
    }

    /**
     *
     */
    public get isValid (): boolean {
        return this._isValid
    }

    /**
     *
     */
    constructor (number: string) {
        // TODO: formatting
        this._number = formatToDigits(number)
        this._isValid = false
        this._isPhoneNumber = false
        this.validateNumber()
    }

    /**
     * valiate the number an set isValidPhoneNumber
     */
    private validateNumber (): void {
        this._isValid = (this._number.length > 0)
        if (this._isValid && isValidPhoneNumber(this._number)) {
            this._isPhoneNumber = true
            this._number = convertNumberToE164(this._number)
        }
    }

    /**
     *
     */
    public makeURI (): URI {
        return UserAgent.makeURI(`sip:${this.number}phone.com`) as URI
    }
}
