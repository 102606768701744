import React from 'react'

/**
 *
 */
const SignOut = (props: Record<string, any>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            fill="none"
            {...props}
        >
            <g fill="#fff">
                <path d="M7.344 9.114a.957.957 0 1 0 0 1.914h9.135l-2.332 2.297a1 1 0 0 0-.003 1.423l.172.17a1 1 0 0 0 1.41-.003l4.138-4.137a1 1 0 0 0 0-1.415l-4.137-4.137a1 1 0 0 0-1.411-.003l-.172.17a1 1 0 0 0 .002 1.423l2.333 2.298H7.344Z" />
                <path d="M5 0h5a2 2 0 0 1 2 2 .5.5 0 0 1-.5.5H5A2.5 2.5 0 0 0 2.5 5v10A2.5 2.5 0 0 0 5 17.5h6.5a.5.5 0 0 1 .5.5 2 2 0 0 1-2 2H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5Z" />
            </g>
        </svg>
    )
}

export default SignOut
