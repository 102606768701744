import React, { createContext, Component } from 'react'
import { ObservableState } from 'observable'

/**
 *
 */
async function getInputMediaStream (): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream: MediaStream) => {
            return Promise.resolve(stream)
        })
        .catch(err => {
            console.log('catching mic permission error')
            return Promise.reject(err)
        })
}

/**
 *
 */
async function getPlaybackDevices (): Promise<MediaDeviceInfo[]> {
    return navigator.mediaDevices.enumerateDevices()
        .then((devices: MediaDeviceInfo[]) => {
            return Promise.resolve(devices.filter(device => device.kind === 'audiooutput'))
        })
        .catch(err => {
            console.log('Error getting media devices', err)
            return Promise.reject(err)
        })
}

/**
 *
 */
async function getRecordingDevices (): Promise<MediaDeviceInfo[]> {
    return navigator.mediaDevices.getUserMedia({ audio: true }).then(() =>
        navigator.mediaDevices.enumerateDevices()
            .then((devices: MediaDeviceInfo[]) => {
                return Promise.resolve(devices.filter(device => device.kind === 'audioinput'))
            })
            .catch(err => {
                console.error('Error getting input media devices', err)
                return Promise.reject(err)
            })
    )
}

interface IAudioDevicesState {
    inputDevices: MediaDeviceInfo[]
    outputDevices: MediaDeviceInfo[]
}

class AudioDevices extends ObservableState<IAudioDevicesState> {
    constructor () {
        super({ inputDevices: [], outputDevices: [] })
        this.fetchMediaDevices()
        if (navigator?.mediaDevices) {
            navigator.mediaDevices.ondevicechange = this.fetchMediaDevices
        }
    }

    private fetchMediaDevices = async () => {
        console.log('PdcCallClient audio devices changed')
        await navigator?.mediaDevices?.getUserMedia({ audio: true }).then((stream) => {
            const audio = document.getElementById('audiodevices') as HTMLAudioElement
            audio.srcObject = stream
            return navigator.mediaDevices.enumerateDevices()
                .then((devices: MediaDeviceInfo[]) => {
                    const outputDevices = devices.filter(device => device.kind === 'audiooutput')
                    const inputDevices = devices.filter(device => device.kind === 'audioinput')
                    this.setState({ inputDevices, outputDevices })
                    console.log('PdcCallClient audio devices changed', this.state)
                }).catch(err => {
                    console.error('Error getting media devices', err)
                    return Promise.reject(err)
                })
        })
    }
}

const AudioDevicesContext = createContext<IAudioDevicesState>({} as IAudioDevicesState)

class AudioDevicesComponent extends Component<{children: JSX.Element}, IAudioDevicesState> {
    private audioDevices
    /**
     *
     */
    constructor (props) {
        super(props)
        this.state = {
            inputDevices: [],
            outputDevices: []
        }
        this.audioDevices = new AudioDevices()
        this.updateDevices(this.audioDevices.getState())
        this.audioDevices.subscribe(this.updateDevices)
    }

    private updateDevices = (devices: IAudioDevicesState) => {
        this.setState(devices)
    }

    /**
     *
     */
    render () {
        return (
            <AudioDevicesContext.Provider value={this.state}>
                <AudioDevicesContext.Consumer>
                    {value => <> <audio id='audiodevices' />{this.props.children}</>}
                </AudioDevicesContext.Consumer>
            </AudioDevicesContext.Provider>
        )
    }
}
/**
 *
 */
export { AudioDevices, AudioDevicesComponent, AudioDevicesContext, getInputMediaStream, getPlaybackDevices, getRecordingDevices }
