/**
 *
 */
const dialpadStyles = (theme) => ({
    dialpad: {
        display: 'flex',
        flexDirection: 'column',
        '&.padding': {
            paddingTop: 49
        },
        '& .dialpad-row': {
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: '12px'
        },
        '& .key-item': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 62,
            height: 62,
            borderRadius: 5,
            color: theme.palette.text.primary,
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.action.primaryFocus,
                '& > .item-char, & > .item-letters': {
                    color: theme.palette.primary.dark
                }
            },
            '& > .item-char': {
                '& .number': {
                    fontFamily: 'Montserrat-medium!important',
                    fontSize: '21px!important',
                    color: `${theme.palette.text.primary}!important`,
                    fontStyle: 'normal!important',
                    lineHeight: '26px!important'
                },
                '&.star > p': {
                    fontSize: '43px !important',
                    paddingTop: 16
                }
            },
            '& > .item-letters': {
                height: 24,
                color: theme.palette.text.secondary,
                marginTop: -10,
                '& .letters': {
                    fontFamily: 'Montserrat-medium!important',
                    fontSize: '10px!important',
                    color: `${theme.palette.text.secondary}!important`,
                    fontStyle: 'normal!important',
                    lineHeight: '30px!important',
                    letterSpacing: '0.4px',
                    textTransform: 'uppercase'
                },
                '&.plus': {
                    paddingTop: 6,
                    '& > span': {
                        fontSize: '20px !important'
                    }
                }
            }
        }
    }
})

/**
 *
 */
export { dialpadStyles }
