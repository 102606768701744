import React from 'react'
import Typography from 'typography'
import { Button } from 'button-mui'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import Divider from '@material-ui/core/Divider'
import { Logo } from '../Logo'

const useStyles = makeStyles(styles)

const disconnectZohoHeading = 'Disconnect Zoho from your account'
const disconnectZohoInfo = 'You are disconnecting Phone.com from your Zoho account. This action will end integration with Phone.com and Zoho. You will no longer see Phone.com calls in your Zoho dashboard, and you will no longer be able to make or receive calls with the Zoho Dashboard.'
const disconnectZohoText = 'Continue to disconnect Zoho?'

/**
 *
 */
export function DeleteIntegration ({ disconnectHandle, openDeleteIntegrationPopupHandler, closePopupComponent }: { disconnectHandle: () => void, openDeleteIntegrationPopupHandler: (value: boolean) => void, closePopupComponent: JSX.Element }): JSX.Element {
    const classes = useStyles()

    return (
        <>
            {closePopupComponent}
            <div className={classes.deleteIntegrationScreenRoot}>
                <div className={'logo'}>
                    <Logo />
                </div>
                <Divider className='divider' />
                <div className='disconnect-zoho-info'>
                    <Typography className='disconnect-zoho-heading'>
                        {disconnectZohoHeading}
                    </Typography>
                    <Typography className='disconnect-zoho-info-text'>
                        {disconnectZohoInfo}
                    </Typography>
                    <div className='disconnect-zoho-text-root'>
                        <Typography className='disconnect-zoho-text'>
                            {disconnectZohoText}
                        </Typography>
                        <div className='btn-root'>
                            <Button variant='contained' color='secondary' className='disconnect-cancel-btn' onClick={() => openDeleteIntegrationPopupHandler(false)}>Cancel</Button>
                            <Button variant='contained' color='urgent' className='disconnect-btn' onClick={disconnectHandle}>
                                Disconnect
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
