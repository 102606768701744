
class Observable<T> {
    private observers: ((data: T) => void)[] = []

    /**
     *
     */
    public subscribe (observer: (data: T) => void): void {
        this.observers.push(observer)
    }

    /**
     *
     */
    public unsubscribe (observer: (data: T) => void): void {
        this.observers = this.observers.filter(o => o !== observer)
    }

    /**
     *
     */
    protected notify (data: T): void {
        this.observers.forEach(observer => observer(data))
    }
}

class ObservableState<T> extends Observable<T> {
    protected state: T
    /**
     *
     */
    constructor (initialState: T) {
        super()
        this.state = initialState
    }

    /**
     *
     */
    protected setState (update: { [key: string]: any}): void {
        for (const [key, value] of Object.entries(update)) {
            if (typeof this.state[key] !== 'undefined') {
                this.state[key] = value
            }
        }
        this.notify(this.state)
    }

    /**
     *
     */
    public getState (): T {
        return this.state
    }
}

/**
 *
 */
export { Observable, ObservableState }
