/**
 *
 */
const styles = (theme) => ({
    integrationScreenRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        zIndex: 9999,
        '& .logos': {
            display: 'flex',
            columnGap: '16px',
            paddingTop: '50%',
            '& .divider': {
                width: '2px'
            }
        },
        '& .sign-in-prompt-text': {
            paddingTop: '5%',
            paddingLeft: '5%',
            paddingRight: '5%',
            '& .sign-in-details': {
                color: theme.palette.text.primary,
                fontFamily: 'Montserrat!important',
                fontSize: '12px!important',
                fontWeight: '600!important',
                lineHeight: '19px!important',
                textAlign: 'center'
            }
        },
        '& .sign-in-btn-root': {
            paddingTop: '20%',
            paddingBottom: '20%',
            '& .sign-in-btn': {
                '& .sign-in-btn-text': {
                    fontFamily: 'Montserrat!important',
                    fontSize: '13px!important',
                    fontWeight: '600!important',
                    lineHeight: '16px!important'
                }
            }
        }
    }
})

export default styles
