import { PdcIntegrationConfig, PdcIntegrationID, IntegrationDetails } from './PdcIntegration'
import { PdcZohoIntegration, deleteZohoIntegration } from './PdcZohoIntegration'
export * from './PdcIntegration'
/**
 *
 */
export const initIntegration = async (config: PdcIntegrationConfig): Promise<IntegrationDetails> => {
    switch (config.id) {
            case PdcIntegrationID.ZOHO:
                // eslint-disable-next-line no-case-declarations
                const zoho = new PdcZohoIntegration(config)
                return zoho.fetchIntegrationDetails()
            default:
                throw new Error('Invalid integration')
    }
}

/**
 *
 */
export const deleteIntegration = async (integrationID: string): Promise<void> => {
    if (integrationID === PdcIntegrationID.ZOHO) {
        await deleteZohoIntegration()
    }
}
