/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { PdcClientApp } from 'my-pdc-client'
interface IntegrationDetails {
    integrated: boolean
    redirectURI: string
    integrationName: string
}

interface PdcIntegrationConfig {
    client: PdcClientApp
    id: string
}

interface IntegrationCallEvent {
    event: 'new_call' | 'end_call' | 'integrate' | 'delete_integration',
    integration: string,
    data?: {
        pdc_call_id: string,
        integration_record_id: string,
        phone_number: string,
    }
}

const enum PdcIntegrationID {
    ZOHO = 'zoho_crm'
}

abstract class PdcIntegration {
    // STAGE / PROD
    private getIntgrationDetailsURL = 'https://integrations.phone.com/crm/v2/integration-details'
    protected mClient: PdcClientApp
    protected mIntegrationName: string
    /**
     *
     */
    public constructor (config: PdcIntegrationConfig) {
        this.mClient = config.client
        this.mIntegrationName = config.id
    }

    public fetchIntegrationDetails = async (): Promise<IntegrationDetails> => {
        const request = {
            url: this.getIntgrationDetailsURL,
            payload: {
                // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
                integration_name: this.mIntegrationName
            }
        }
        // local testing, dont use api, just set active false
        const res = await PdcClientApp.api().post(request)
        console.log('res: ', res)
        const details = { integrated: res.details.active, redirectURI: await this.getIntegrationURI(), integrationName: this.mIntegrationName }
        console.log('details: ', details)
        return details
    }

    protected abstract getIntegrationURI(): Promise<string>
}

/**
 *
 */
export { PdcIntegration, PdcIntegrationID, IntegrationDetails, PdcIntegrationConfig, IntegrationCallEvent }
