/**
 *
 */
const viewStyles = (theme) => ({
    viewRoot: {
        height: ({ title }) => title !== 'Incoming Call' ? 'auto' : '100%',
        '& .viewHeaderRoot': {
            height: ({ title }) => title !== 'Incoming Call' ? '129px' : '212px'
        },
        '& .viewBodyFooterRoot': {
            height: ({ title }) => title !== 'Incoming Call' ? 'auto' : 'calc(100% - 212px)'
        },
        '& .viewContentRoot': {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            '& .viewBody': {
                height: '80%'
            },
            '& .viewFooter': {
                height: '20%'
            }
        }
    }
})

/**
 *
 */
export { viewStyles }
