import React from 'react'
import Typography from 'typography'
import { Button } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import Divider from '@material-ui/core/Divider'
import ZohoIcon from '../SVG/Zoho'
import Arrow from '../SVG/Arrow'

const useStyles = makeStyles(styles)

/**
 *
 */
export function SignInComponent (props: { openLoginWindow: () => void }): JSX.Element {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <div className={classes.integrationScreenRoot}>
            <div className={'logos'}>
                <img src={(theme as Record<string, any>).icons?.identityAreaLogo} alt="pdc logo" style={{ width: '56px' }} />

                {(window.pdcIntegrationID === 'zoho_crm') ? <> <Divider className={'divider'} orientation="vertical" flexItem /><ZohoIcon /></> : <></>}

            </div>
            <div className={'sign-in-btn-root'}>
                <Button onClick={props.openLoginWindow} variant='contained' color='primary' size='large' endIcon={<Arrow />} className={'sign-in-btn'}>
                    <Typography className='sign-in-btn-text'>SIGN IN</Typography>
                </Button>
            </div>
            <div className={'sign-in-prompt-text'}>
                <Typography className='zoho-details' variant='body2' remoteConfigID='web_phone_sign_in_prompt'>
                    To sign in, click the button above. If the sign in window does not open, please disable your pop-up blocker.
                </Typography>
            </div>
        </div>
    )
}
