import { PdcClientApp } from 'my-pdc-client'
import { PdcIntegration, PdcIntegrationID } from './PdcIntegration'
/**
 *
 */
export class PdcZohoIntegration extends PdcIntegration {
    private mClientId = '1000.4BAQZ72TTC85Y4WZBRONTP7ZPP3Z9H'
    // STAGE / PROD
    private mRedirectURI = 'https://integrations.phone.com/crm/v2/zoho/integrate'
    private mIntegrationScope = 'ZohoCRM.modules.ALL,ZohoCRM.settings.ALL'
    private mIntegrationURI = `https://accounts.zoho.com/oauth/v2/auth?scope=${this.mIntegrationScope}&client_id=${this.mClientId}&response_type=code&access_type=offline&redirect_uri=${this.mRedirectURI}`
    public id = PdcIntegrationID.ZOHO
    /**
     *
     */
    public getIntegrationURI = async (): Promise<string> => {
        const userInfo = PdcClientApp.user()
        const userParams = '&state=' + userInfo.sessionId
        return this.mIntegrationURI + userParams
    }
}

/**
 *
 */
export const deleteZohoIntegration = async (): Promise<void> => {
    const url = 'https://integrations.phone.com/crm/v2/zoho/delete-integration'
    return await PdcClientApp.api().post({ url })
}
