
/**
 *
 */
const styles = (theme) => ({
    addNotesPanel: {
        height: '100%',
        width: '100%',
        '& .notesTextArea': {
            width: '100%',
            borderRadius: '6px',
            border: `1px solid ${theme.palette.secondary.light}`,
            padding: '10px',
            fontSize: '12px',
            fontWeight: 500,
            color: theme.palette.text.primary,
            resize: 'none',
            boxSizing: 'border-box',
            '&::placeholder': {
                fontSize: '12px',
                fontWeight: 500,
                color: '#8C98A0'
            }
        }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        spaceBetween: '20px',
        marginTop: '30px',
        '& button': {
            width: '110px',
            height: '40px'
        }

    }
})

export default styles
