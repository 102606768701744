import headerImage from './imgs/header-background.png'

/**
 *
 */
const headerStyles = (theme) => ({
    phoneHeaderRoot: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${headerImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        '& .headerContent': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }
    }
})

/**
 *
 */
export { headerStyles }
