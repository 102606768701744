import { Session } from 'sip.js'
import { formatPhoneNumber } from 'phone-numbers'
import { IContactInfo } from 'my-pdc-client'
import { Observable } from 'observable'
// import { ContactStore } from '../../data-store'

/**
 *
 */
export class CallUser extends Observable<void> {
    // same as original session id
    public sessionId: string
    public callerId: string
    public contactId: string
    public phoneNumber: string
    public displayName: string

    /**
     *
     */
    constructor (session: Session) {
        super()
        this.phoneNumber = session.remoteIdentity.uri.user || ''
        this.sessionId = session.id
        this.callerId = formatPhoneNumber(this.phoneNumber)
        this.displayName = this.callerId
        this.contactId = ''
    }

    /**
     *
     */
    public setContactInfo (contactInfo: IContactInfo) {
        this.contactId = contactInfo.id
        this.displayName = contactInfo.name
        this.notify()
    }
    /*
    private async findContactInfo (): Promise<void> {
        return ContactStore.findByPhoneNumber(this.phoneNumber).then(contactInfo => {
            if (contactInfo) {
                if (contactInfo.deleted) {
                    this.contactId = 0
                    this.displayName = this.phoneNumber
                } else {
                    this.contactId = contactInfo.contactId
                    this.displayName = contactInfo.name.nick || `${contactInfo.name.first} ${contactInfo.name.last}` || this.phoneNumber
                }
                this.onUpdated()
            }
        })
    }
    */
}
